import { apiClient as axios } from "./axiosAdapter";

const API_URL = "monitoring/";

class MonitoringService {
  updateUnitsStatus(date) {
    return axios.get(API_URL + "units_status/", { params: { date } });
  }
  parseMails() {
    return axios.get(API_URL + "parse_mails/");
  }
  updateTaskStatus(task_id) {
    return axios.get(API_URL + "task_status/", { params: { task_id } });
  }
  updateUnitsStatistic(params) {
    return axios.get(API_URL + "units_statistic/", { params });
  }
  fetchNotifications() {
    return axios.get(API_URL + "notifications/");
  }
  fetchNotification(notificationId) {
    return axios.get(API_URL + `notifications/${notificationId}/`);
  }
  createNotification(form) {
    return axios.post(API_URL + "notifications/create", form);
  }
  updateNotification(form) {
    return axios.put(API_URL + `notifications/${form.id}/`, form);
  }
  deleteNotification(notificationId) {
    return axios.delete(API_URL + `notifications/${notificationId}/`);
  }
  fetchNotificationsRecipientsAndUnits() {
    return axios.get(API_URL + "notifications/units_and_recipients/");
  }
  createRecipient(form) {
    return axios.post(API_URL + "notifications/recipients/create", form);
  }
  updateRecipient(form) {
    return axios.put(API_URL + `notifications/recipients/${form.id}/`, form);
  }
  deleteRecipient(recipientId) {
    return axios.delete(API_URL + `notifications/recipients/${recipientId}/`);
  }
  fetchNotificationsHistory(params) {
    return axios.get(API_URL + "notifications/history", { params });
  }
  fetchSubunitContacts(subunitId) {
    return axios.get(API_URL + `subunit/${subunitId}/contacts`);
  }
  fetchUnitContacts(unitId) {
    return axios.get(API_URL + `unit/${unitId}/contacts`);
  }
}

export default new MonitoringService();
