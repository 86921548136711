import { apiClient as axios, apiFileWatchClient } from './axiosAdapter'

const API_URL = 'contracts/'
const CONTACTS_API_URL = 'contacts/'

class ContractsService {
  fetchContracts(params) {
    return axios.get(API_URL, { params })
  }
  fetchContractsResponsible(params) {
    return axios.get(`${API_URL}responsible/`, { params })
  }
  changeContractResponsible(contractId, form) {
    return axios.post(`${API_URL}${contractId}/responsible/`, form)
  }
  fetchCustomersBySearch(search) {
    return axios.get(`${API_URL}customers/`, { params: { search, disable_pagination: true } })
  }
  fetchContractSteps(contractId) {
    return axios.get(`${API_URL}${contractId}/steps/`)
  }
  fetchContract(contractId) {
    return axios.get(`${API_URL}${contractId}/`)
  }
  createContract(form) {
    return axios.post(API_URL, form)
  }
  editContract(contractId, form) {
    return axios.patch(`${API_URL}${contractId}/`, form)
  }
  changeArchiveContract(contractId) {
    return axios.post(`${API_URL}${contractId}/archive/`)
  }
  deleteContract(contractId) {
    return axios.delete(`${API_URL}${contractId}/`)
  }
  fetchContacts(params) {
    return axios.get(CONTACTS_API_URL + `customers/`, { params })
  }
  createContact(form) {
    return axios.post(CONTACTS_API_URL + `customers/`, form)
  }
  deleteContact(contactId) {
    return axios.delete(CONTACTS_API_URL + `customers/${contactId}/`);
  }
  editContact(contactId, form){
    return axios.patch(CONTACTS_API_URL + `customers/${contactId}/`, form)
  }
  fetchContractAgreements(contractId) {
    return axios.get(`${API_URL}${contractId}/agreements/`)
  }
  downloadContractFile(contractId) {
    return apiFileWatchClient.get(`${API_URL}${contractId}/file/`)
  }
  downloadContractAgreement(agreementId) {
    return apiFileWatchClient.get(`${API_URL}agreements/${agreementId}/`)
  }
  deleteContractAgreement(agreementId) {
    return axios.delete(`${API_URL}agreements/${agreementId}/`)
  }
  createContractAgreement(form) {
    return axios.post(`${API_URL}agreements/`, form)
  }
  createStep(form) {
    return axios.post(`${API_URL}steps/`, form)
  }
  editStep(stepId, form) {
    return axios.patch(`${API_URL}steps/${stepId}/`, form)
  }
  deleteStep(stepId) {
    return axios.delete(`${API_URL}steps/${stepId}/`)
  }
}

export default new ContractsService()
