import actions from './actions'
import * as getters from './getters'
import mutations from './mutations'

const initialState = {
  workload: [],
  tags: [],
  objectsPto: [],
  isLoading: false,
  isLoadingPto: false,
  activeId: 0,
  scrollTop: 0,
  scrollLeft: 0
}

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters
}
