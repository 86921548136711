<template>
  <div class="container" v-if="user">
    <el-container>
      <el-header>
        <res-nav />
      </el-header>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  props: { user: { type: Object, required: false } }
}
</script>
