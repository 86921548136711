import { apiClient as axios } from './axiosAdapter'

const API_URL = 'remouteaccess/'

class RemouteService {
  fetchObjs() {
    return axios.get(API_URL + 'obj/')
  }
  fetchProblems() {
    return axios.get(API_URL + 'problems/')
  }
  fetchDostups(id) {
    return axios.get(API_URL + `obj_dostup/${id}/`)
  }
  fetchInfo(id) {
    return axios.get(API_URL + `obj_info/${id}/`)
  }
  createObj(form) {
    return axios.post(API_URL + 'obj/', form)
  }
  createProblems(form) {
    return axios.post(API_URL + 'problems/', form)
  }
  editObj(form) {
    return axios.put(API_URL + `obj/${form.id}/`, form)
  }
  editProblems(form) {
    return axios.put(API_URL + `problems/${form.id}/`, form)
  }
  deleteProblems(problemId) {
    return axios.delete(API_URL + `problems/${problemId}/`)
  }
  deleteObj(objId) {
    return axios.delete(API_URL + `obj/${objId}/`)
  }
  createContacter(form) {
    return axios.post(API_URL + 'contacters/', form)
  }
  editContacter(form) {
    return axios.put(API_URL + `contacters/${form.id}/`, form)
  }
  deleteContacter(contacterId) {
    return axios.delete(API_URL + `contacters/${contacterId}/`)
  }
  createInfo(form) {
    return axios.post(API_URL + 'info/', form)
  }
  createDostup(form) {
    return axios.post(API_URL + 'dostup/', form)
  }
  editDostup(form) {
    return axios.put(API_URL + `dostup/${form.id}/`, form)
  }
  deleteDostup(dostupId) {
    return axios.delete(API_URL + `dostup/${dostupId}/`)
  }
  editInfo(form) {
    return axios.put(API_URL + `info/${form.id}/`, form)
  }
  deleteInfo(infoId) {
    return axios.delete(API_URL + `info/${infoId}/`)
  }
}

export default new RemouteService()
