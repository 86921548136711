export const FETCH_WORKLOAD = 'FETCH_WORKLOAD'
export const FETCH_WORKLOAD_SUCCESS = 'FETCH_WORKLOAD_SUCCESS'
export const FETCH_WORKLOAD_ERROR = 'FETCH_WORKLOAD_ERROR'

export const FETCH_WORKLOAD_OBJECT_PTO = 'FETCH_WORKLOAD_OBJECT_PTO'
export const FETCH_WORKLOAD_OBJECT_PTO_SUCCESS = 'FETCH_WORKLOAD_OBJECT_PTO_SUCCESS'
export const FETCH_WORKLOAD_OBJECT_PTO_ERROR = 'FETCH_WORKLOAD_OBJECT_PTO_ERROR'

export const FETCH_WORKLOAD_TAGS = 'FETCH_WORKLOAD_TAGS'
export const FETCH_WORKLOAD_TAGS_SUCCESS = 'FETCH_WORKLOAD_TAGS_SUCCESS'
export const FETCH_WORKLOAD_TAGS_ERROR = 'FETCH_WORKLOAD_TAGS_ERROR'
export const SET_ACTIVE_ROW = 'SET_ACTIVE_ROW'
export const SET_SCROLL_TOP = 'SET_SCROLL_TOP'
export const SET_SCROLL_LEFT = 'SET_SCROLL_LEFT'
