import InvoicesService from "@/services/invoices.service";

const initialState = {
  loading: false,
  totalPages: 0,
  invoices: [],
  suppliers: [],
  customers: [],
  specifications: []
};

export const invoices = {
  namespaced: true,
  state: initialState,
  actions: {
    changeInvoiceAgreed({ commit }, form) {
      commit("invoicesLoading", true);
      return InvoicesService.changeInvoiceAgreed(form).then(
        () => {
          commit("invoicesLoading", false);
          commit("setMessage", { text: "changeInvoiceAgreedSuccess", type: "success" }, { root: true });
          return Promise.resolve();
        },
        error => {
          commit("invoicesLoading", false);
          return Promise.reject(error);
        }
      );
    },
    updateInvoices({ dispatch, commit }, params) {
      commit("invoicesLoading", true);
      dispatch("updateInvoicesInfo");

      return InvoicesService.updateInvoices(params).then(
        invoices => {
          commit("invoicesSuccess", invoices);
          return Promise.resolve(invoices);
        },
        error => {
          commit("invoicesFailure");
          return Promise.reject(error);
        }
      );
    },
    updateInvoicesInfo({ commit }) {
      return InvoicesService.updateInvoicesInfo().then(
        invoices => {
          commit("invoicesInfoSuccess", invoices);
          return Promise.resolve(invoices);
        },
        error => {
          commit("invoicesInfoFailure");
          return Promise.reject(error);
        }
      );
    },
    createInvoice({ commit }, form) {
      return InvoicesService.createInvoice(form).then(invoice => {
        commit("setMessage", { text: "invoicesAddSuccess", type: "success" }, { root: true });
        return Promise.resolve(invoice);
      });
    },
    editInvoice({ commit }, { invoiceId, form }) {
      return InvoicesService.editInvoice(invoiceId, form).then(invoice => {
        commit("setMessage", { text: "changeInvoiceSuccess", type: "success" }, { root: true });
        return Promise.resolve(invoice);
      });
    },
    removeInvoice({ commit }, invoiceId) {
      commit("invoicesLoading", true);
      return InvoicesService.removeInvoice(invoiceId).then(
        () => {
          commit("invoicesLoading", false);
          commit("setMessage", { text: "removeInvoiceSuccess", type: "success" }, { root: true });
          return Promise.resolve();
        },
        () => {
          commit("invoicesLoading", false);
        }
      );
    },

    editInvoiceAgreed({ commit }, invoiceId) {
      return InvoicesService.editInvoiceAgreed(invoiceId).then(invoice => {
        commit("setMessage", { text: "changeInvoiceAgreedSuccess", type: "success" }, { root: true });
        return Promise.resolve(invoice);
      });
    },
    addSpecification({ commit }, form) {
      return InvoicesService.addSpecification(form).then(specification => {
        commit("setMessage", { text: "invoiceSpecificationAddSuccess", type: "success" }, { root: true });
        return Promise.resolve(specification);
      });
    },
    bindSpecification({ commit }, form) {
      return InvoicesService.bindSpecification(form).then(specification => {
        commit("setMessage", { text: "invoiceSpecificationBindSuccess", type: "success" }, { root: true });
        return Promise.resolve(specification);
      });
    },
    unBindSpecification({ commit }, form) {
      return InvoicesService.unBindSpecification(form).then(specification => {
        commit("setMessage", { text: "invoiceSpecificationUnBindSuccess", type: "success" }, { root: true });
        return Promise.resolve(specification);
      });
    },
    changeSpecification({ commit }, form) {
      return InvoicesService.changeSpecification(form).then(specification => {
        commit("setMessage", { text: "invoiceSpecificationChangeSuccess", type: "success" }, { root: true });
        return Promise.resolve(specification);
      });
    },
    removeSpecification({ commit }, form) {
      return InvoicesService.removeSpecification(form).then(specification => {
        commit("setMessage", { text: "invoiceSpecificationRemoveSuccess", type: "success" }, { root: true });
        return Promise.resolve(specification);
      });
    }
  },
  mutations: {
    invoicesLoading(state, status) {
      state.loading = status;
    },
    invoicesSuccess(state, invoices) {
      state.loading = false;
      state.invoices = invoices.results;
      state.totalPages = invoices.total;
    },
    invoicesFailure(state) {
      state.loading = false;
      state.invoices = [];
    },
    invoicesInfoSuccess(state, invoicesInfo) {
      state.suppliers = invoicesInfo.suppliers;
      state.customers = invoicesInfo.customers;
      state.specifications = invoicesInfo.specifications;
    },
    invoicesInfoFailure(state) {
      state.suppliers = [];
      state.customers = [];
      state.specifications = [];
    }
  }
};
