import axios from "axios";
import {
  authFileHandler,
  authHandler,
  dataFileHandler,
  dataFileWatchHandler,
  dataUnpackHandler,
  errorHandlerFactory
} from "./interceptors";

const BASE_URL = "/api/";

export const apiClient = axios.create({ baseURL: BASE_URL });
apiClient.interceptors.response.use(dataUnpackHandler, errorHandlerFactory(apiClient));
apiClient.interceptors.request.use(authHandler);

export const apiFileDownloadClient = axios.create({ baseURL: BASE_URL });
apiFileDownloadClient.interceptors.response.use(dataFileHandler, errorHandlerFactory(apiFileDownloadClient));
apiFileDownloadClient.interceptors.request.use(authFileHandler);

export const apiFileWatchClient = axios.create({ baseURL: BASE_URL });
apiFileWatchClient.interceptors.response.use(dataFileWatchHandler, errorHandlerFactory(apiFileWatchClient));
apiFileWatchClient.interceptors.request.use(authFileHandler);

export default apiClient;
