import RemouteService from '@/services/remoute.service'

const initialState = {
  loading: false,
  loadingDostups: false,
  loadingInfo: false,
  objs: [],
  dostups: [],
  info: [],
  problems: [],
}

export const remoute = {
  namespaced: true,
  state: initialState,
  actions: {
    fetchObjs({ commit }) {
      commit('changeLoading', true)
      return RemouteService.fetchObjs().then(
        (objs) => {
          commit('fetchObjsSuccess', objs)
          return Promise.resolve(objs)
        },
        (error) => {
          commit('fetchObjsFailure')
          return Promise.reject(error)
        }
      )
    },
    fetchProblems({ commit }) {
      commit('changeLoading', true)
      return RemouteService.fetchProblems().then(
        (problems) => {
          commit('fetchProblemsSuccess', problems)
          return Promise.resolve(problems)
        },
        (error) => {
          commit('fetchProblemsFailure')
          return Promise.reject(error)
        }
      )
    },
    fetchDostups({ commit }, id) {
      commit('changeLoadingDostup', true)
      return RemouteService.fetchDostups(id).then(
        (dostups) => {
          commit('fetchDostupsSuccess', dostups)
          return Promise.resolve(dostups)
        },
        (error) => {
          commit('fetchDostupsFailure')
          return Promise.reject(error)
        }
      )
    },
    fetchInfo({ commit }, id) {
      commit('changeLoadingInfo', true)
      return RemouteService.fetchInfo(id).then(
        (info) => {
          commit('fetchInfoSuccess', info)
          return Promise.resolve(info)
        },
        (error) => {
          commit('fetchInfoFailure')
          return Promise.reject(error)
        }
      )
    },
    createObj({ commit, dispatch }, form) {
      commit('changeLoading', true)
      return RemouteService.createObj(form).then(
        (data) => {
          commit('changeLoading', false)
          commit('setMessage', { text: 'createRemouteObjSuccess', type: 'success' }, { root: true })
          dispatch('fetchObjs')
          return Promise.resolve(data)
        },
        (error) => {
          commit('changeLoading', false)
          return Promise.reject(error)
        }
      )
    },
    createProblems({ commit, dispatch }, form) {
      commit('changeLoading', true)
      return RemouteService.createProblems(form).then(
        (data) => {
          commit('changeLoading', false)
          commit('setMessage', { text: 'createRemouteProblemsSuccess', type: 'success' }, { root: true })
          dispatch('fetchProblems')
          return Promise.resolve(data)
        },
        (error) => {
          commit('changeLoading', false)
          return Promise.reject(error)
        }
      )
    },
    editObj({ commit, dispatch }, form) {
      commit('changeLoading', true)
      return RemouteService.editObj(form).then(
        (data) => {
          commit('changeLoading', false)
          commit('setMessage', { text: 'editRemouteObjSuccess', type: 'success' }, { root: true })
          dispatch('fetchObjs')
          return Promise.resolve(data)
        },
        (error) => {
          commit('changeLoading', false)
          return Promise.reject(error)
        }
      )
    },
    editProblems({ commit, dispatch }, form) {
      commit('changeLoading', true)
      return RemouteService.editProblems(form).then(
        (data) => {
          commit('changeLoading', false)
          commit('setMessage', { text: 'editRemouteProblemsSuccess', type: 'success' }, { root: true })
          dispatch('fetchProblems')
          return Promise.resolve(data)
        },
        (error) => {
          commit('changeLoading', false)
          return Promise.reject(error)
        }
      )
    },
    deleteObj({ commit, dispatch }, objId) {
      commit('changeLoading', true)
      return RemouteService.deleteObj(objId).then(
        (data) => {
          commit('changeLoading', false)
          commit('setMessage', { text: 'deleteRemouteObjSuccess', type: 'success' }, { root: true })
          dispatch('fetchObjs')
          return Promise.resolve(data)
        },
        (error) => {
          commit('changeLoading', false)
          return Promise.reject(error)
        }
      )
    },
    deleteProblems({ commit, dispatch }, problemId) {
      commit('changeLoading', true)
      return RemouteService.deleteProblems(problemId).then(
        (data) => {
          commit('changeLoading', false)
          commit('setMessage', { text: 'deleteRemouteProblemsSuccess', type: 'success' }, { root: true })
          dispatch('fetchProblems')
          return Promise.resolve(data)
        },
        (error) => {
          commit('changeLoading', false)
          return Promise.reject(error)
        }
      )
    },
    createContacter({ commit, dispatch }, form) {
      commit('changeLoading', true)
      return RemouteService.createContacter(form).then(
        (data) => {
          commit('changeLoading', false)
          commit('setMessage', { text: 'createRemouteContacterSuccess', type: 'success' }, { root: true })
          dispatch('fetchObjs')
          return Promise.resolve(data)
        },
        (error) => {
          commit('changeLoading', false)
          return Promise.reject(error)
        }
      )
    },
    editContacter({ commit, dispatch }, form) {
      commit('changeLoading', true)
      return RemouteService.editContacter(form).then(
        (data) => {
          commit('changeLoading', false)
          commit('setMessage', { text: 'editRemouteInfoSuccess', type: 'success' }, { root: true })
          dispatch('fetchObjs')
          return Promise.resolve(data)
        },
        (error) => {
          commit('changeLoading', false)
          return Promise.reject(error)
        }
      )
    },
    deleteContacter({ commit, dispatch }, contacterId) {
      commit('changeLoading', true)
      return RemouteService.deleteContacter(contacterId).then(
        (data) => {
          commit('changeLoading', false)
          commit('setMessage', { text: 'deleteRemouteContacterSuccess', type: 'success' }, { root: true })
          dispatch('fetchObjs')
          return Promise.resolve(data)
        },
        (error) => {
          commit('changeLoading', false)
          return Promise.reject(error)
        }
      )
    },
    createInfo({ commit, dispatch }, form) {
      commit('changeLoadingInfo', true)
      return RemouteService.createInfo(form).then(
        (data) => {
          commit('setMessage', { text: 'createRemouteInfoSuccess', type: 'success' }, { root: true })
          dispatch('fetchInfo', form.obj)
          return Promise.resolve(data)
        },
        (error) => {
          commit('changeLoading', false)
          return Promise.reject(error)
        }
      )
    },
    editInfo({ commit, dispatch }, { id, form }) {
      commit('changeLoadingInfo', true)
      return RemouteService.editInfo(form).then(
        (data) => {
          commit('setMessage', { text: 'editRemouteInfoSuccess', type: 'success' }, { root: true })
          dispatch('fetchInfo', id)
          return Promise.resolve(data)
        },
        (error) => {
          commit('changeLoading', false)
          return Promise.reject(error)
        }
      )
    },
    deleteInfo({ commit, dispatch }, { id, infoId }) {
      commit('changeLoadingInfo', true)
      return RemouteService.deleteInfo(infoId).then(
        (data) => {
          commit('setMessage', { text: 'deleteRemouteInfoSuccess', type: 'success' }, { root: true })
          dispatch('fetchInfo', id)
          return Promise.resolve(data)
        },
        (error) => {
          commit('changeLoading', false)
          return Promise.reject(error)
        }
      )
    },
    createDostup({ commit, dispatch }, form) {
      commit('changeLoadingDostup', true)
      return RemouteService.createDostup(form).then(
        (data) => {
          commit('changeLoadingDostup', false)
          commit('setMessage', { text: 'createRemouteDostupSuccess', type: 'success' }, { root: true })
          dispatch('fetchDostups', form.obj)
          return Promise.resolve(data)
        },
        (error) => {
          commit('changeLoadingDostup', false)
          return Promise.reject(error)
        }
      )
    },
    editDostup({ commit, dispatch }, form) {
      commit('changeLoadingDostup', true)
      return RemouteService.editDostup(form).then(
        (data) => {
          commit('changeLoadingDostup', false)
          commit('setMessage', { text: 'editRemouteDostupSuccess', type: 'success' }, { root: true })
          dispatch('fetchDostups', form.obj)
          return Promise.resolve(data)
        },
        (error) => {
          commit('changeLoadingDostup', false)
          return Promise.reject(error)
        }
      )
    },
    deleteDostup({ commit, dispatch }, variables) {
      commit('changeLoadingDostup', true)
      return RemouteService.deleteDostup(variables.dostupId).then(
        (data) => {
          commit('changeLoadingDostup', false)
          commit('setMessage', { text: 'deleteRemouteDostupSuccess', type: 'success' }, { root: true })
          dispatch('fetchDostups', variables.id)
          return Promise.resolve(data)
        },
        (error) => {
          commit('changeLchangeLoadingDostupoading', false)
          return Promise.reject(error)
        }
      )
    },
    clearInfoContacts({ commit }) {
      commit('clearInfoContacts')
    }
  },
  mutations: {
    changeLoading(state, status) {
      state.loading = status
    },
    changeLoadingDostup(state, status) {
      state.loadingDostups = status
    },
    changeLoadingInfo(state, status) {
      state.loadingInfo = status
    },
    fetchObjsSuccess(state, objs) {
      state.loading = false
      state.objs = objs
    },
    fetchProblemsSuccess(state, problems) {
      state.loading = false
      state.problems = problems
    },
    fetchObjsFailure(state) {
      state.loading = false
      state.objs = []
    },
    fetchProblemsFailure(state) {
      state.loading = false
      state.problems = []
    },
    fetchDostupsSuccess(state, dostups) {
      state.loadingDostups = false
      state.dostups = dostups
    },
    fetchDostupsFailure(state) {
      state.loadingDostups = false
      state.dostups = []
    },
    fetchInfoSuccess(state, info) {
      state.loadingInfo = false
      state.info = info
    },
    fetchInfoFailure(state) {
      state.loadingInfo = false
      state.info = []
    },
    clearInfoContacts(state) {
      state.info = []
    }
  }
}
