<template>
  <component :is="layout" :user="user">
    <router-view />
  </component>
</template>
<script>
import Empty from '@/layouts/Empty'
import Master from '@/layouts/Master'
import { ElMessage } from 'element-plus'

export default {
  components: {
    Empty,
    Master
  },
  mounted() {
    if (!this.user) return
    this.$store.dispatch('auth/updateUserInfo').then(
      () => {
        if (
          !this.routes.find((route) => route.path == window.location.pathname) &&
          !window.location.pathname.includes('/login')
        ) {
          return
        }
        const isAllowedPath = this.userRoutes.find(
          (route) => route.path == window.location.pathname && route.permission
        )
        !isAllowedPath && !this.firstAllowedApp && this.$router.push('/')
        !isAllowedPath && this.firstAllowedApp && this.$router.push(this.firstAllowedApp.path)
      },
      () => this.$router.push('/login')
    )
  },
  computed: {
    layout() {
      return this.$route.meta.layout
    },
    message() {
      return this.$store.state.message
    },
    routes() {
      return this.$store.state.routes
    },
    userRoutes() {
      return this.routes.filter((route) => (route.permission ? this.user.groups.includes(route.permission) : route))
    },
    user() {
      return this.$store.state.auth.user
    },
    firstAllowedApp() {
      return this.userRoutes.find((route) => route.path != '/')
    }
  },
  watch: {
    message(m) {
      ElMessage({ message: m.text || 'Ошибка сервера (500)', type: m.type })
    }
  }
}
</script>
<style lang="scss">
@import '@/scss/_mixins.scss';

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./assets/fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src: local('Roboto'), url(./assets/fonts/Roboto/Roboto-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 600;
  src: local('Roboto'), url(./assets/fonts/Roboto/Roboto-Black.ttf) format('truetype');
}

:root {
  --border-base: #38383a;
  --border-light: #292929;
  --border-opacity: rgba(84, 84, 88, 0.65);
  // --text-primary: #ddd;
  --text-primary: var(--el-text-color-primary);
  --text-secondary: #aaa;
  --text-muted: #868e96;
  --link-border-color: hsla(0, 0%, 100%, 0.3);
  --text-2: rgba(235, 235, 245, 0.6);

  --background: #dadada;
  --background-dark: #444;
  --background-hover: hsla(240, 5%, 48%, 0.1);
  // --background-header: #1c1c1e;
  --background-header: var(--el-bg-color-overlay);
  --background-highlight: rgba(251, 251, 252, 0.035);

  --input-border: #3b3d458f;
  --input-border-focus: #4b4d55;
  --input-bg: #1a1a1a;

  --button: #36373d;
  --button-success-color: #1c7714;
  --button-warning-color: #a07501;
  --button-danger-color: rgb(151, 8, 8);

  // --green: #239019;
  --green: var(--el-color-success);
  --red: #aa1500;
  --yellow: rgb(255, 217, 0);

  --scroll-thumb: #b6b6b6;
  --scroll-track: #727272;

  --staff-weekend-color: hsl(0deg 50% 50%);
  --staff-weekend-color-pto: hsla(221, 51%, 47%, 0.05);
  --staff-weekend-background-color: hsl(0deg 41% 94% / 50%);
  --staff-task-day-hover-color: hsl(0deg 0% 75% / 50%);
  --staff-active-row-hover: rgb(224, 224, 224);
  --staff-link-pto: hsl(213deg 100% 35%);
  --staff-link-pto-hover: hsl(213deg 100% 35%);

  --lecture-success-icon: hsl(120, 50%, 50%);
  --lecture-error-icon: hsl(0, 80%, 50%);

  --rdp-color: hsl(234, 100%, 80%);
  --any-desk-color: hsl(4, 97%, 80%);
  --cisco-any-connect-color: hsl(162, 63%, 56%);
  --rust-desk-color: hsl(213, 100%, 70%);
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  --el-header-padding: 0 !important;
  --el-mask-color: var(--el-bg-color-overlay);
}

body {
  width: 100vw;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, Open Sans, Roboto, Helvetica Neue, Helvetica, sans-serif;
  font-size: 16px;
  background: var(--background);
  color: var(--text-primary);
}
a {
  text-decoration: none;
  color: var(--text);
}

#app {
  width: 100%;
  height: 100%;
}

.container {
  height: 100%;
  width: 100%;
  overflow-x: hidden;

  @include scroll(16px, var(--scroll-track), none, 0);
}

.content {
  position: relative;
  // display: flex;
  // flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 15px 20px;

  transition: all 0.3s ease;
  animation: fade 0.5s;
}

.disabled {
  pointer-events: none;
  filter: brightness(0.9);
}

.red {
  background: var(--red);
}

.green {
  background: var(--green);
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 3px;
  background: var(--green);
  border-radius: 4px;
  transition: all 3s ease;
  max-width: 100%;
  overflow: hidden;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

.mr-10 {
  margin-right: 10px;
}
</style>
