import { fil } from 'date-fns/locale'
import { apiClient as axios } from './axiosAdapter'

const API_URL = 'workload/'

class WorkloadService {
  fetchWorkload(params) {
    return axios.get(API_URL, { params })
  }
  fetchWorkloadPtoObjects(params) {
    return axios.get(`${API_URL}scheduled_maintenance/`, { params })
  }
  createPtoObjects(form) {
    return axios.post(`${API_URL}scheduled_maintenance/`, form)
  }
  updatePtoObjects(objectId, form) {
    return axios.put(`${API_URL}scheduled_maintenance/${objectId}/`, form)
  }
  deletePtoObjects(objectId) {
    return axios.delete(`${API_URL}scheduled_maintenance/${objectId}/`)
  }
  createPtoTask(form) {
    return axios.post(`${API_URL}ptotask/`, form)
  }
  updatePtoTask(taskId, form) {
    return axios.patch(`${API_URL}ptotask/${taskId}/`, form)
  }
  deletePtoTask(taskId) {
    return axios.delete(`${API_URL}ptotask/${taskId}/`)
  }
  fetchWorkloadTask(taskId) {
    return axios.get(`${API_URL}tasks/${taskId}/`)
  }
  fetchWorkloadTags(params) {
    return axios.get(`${API_URL}tags/`, { params })
  }
  createTag(form) {
    return axios.post(`${API_URL}tags/`, form)
  }
  editTag(tagId, form) {
    return axios.put(`${API_URL}tags/${tagId}/`, form)
  }
  deleteTag(tagId) {
    return axios.delete(`${API_URL}tags/${tagId}/`)
  }
  createUnit(form) {
    return axios.post(API_URL, form)
  }
  deleteUnit(unitId) {
    return axios.delete(`${API_URL}${unitId}/`)
  }
  editUnit(unitId, form) {
    return axios.put(`${API_URL}${unitId}/`, form)
  }
  fetchUnits(params) {
    return axios.get(`${API_URL}staff/`, { params })
  }
  createTask(form) {
    return axios.post(`${API_URL}tasks/`, form)
  }
  editTask(taskId, form) {
    return axios.put(`${API_URL}tasks/${taskId}/`, form)
  }
  deleteTask(taskId) {
    return axios.delete(`${API_URL}tasks/${taskId}/`)
  }
  deleteTaskFile(fileId) {
    return axios.delete(`${API_URL}files/${fileId}/`)
  }
  fetchTaskFiles(taskId) {
    return axios.get(`${API_URL}tasks/${taskId}/files/`)
  }
  createTaskFile(form) {
    return axios.post(`${API_URL}files/`, form)
  }
  changeTaskAgreed(taskId) {
    return axios.post(`${API_URL}tasks/${taskId}/agreed/`)
  }
  changeTaskConfirmation(taskId) {
    return axios.post(`${API_URL}tasks/${taskId}/confirm/`)
  }
  fetchContracts(params) {
    return axios.get(`${API_URL}contracts/`, { params })
  }
}

export default new WorkloadService()
