import mutations from "./mutations";
import actions from "./actions";
import * as getters from "./getters";

const user = JSON.parse(localStorage.getItem("user"));
const initialState = { status: { loggedIn: false, callingRefresh: null, callingRefreshCount: 0 }, user: user ?? null };

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters
};
