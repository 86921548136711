import { apiClient as axios } from "./axiosAdapter";

const API_URL = "maintenance/";

class MaintenanceService {
  fetchUnits() {
    return axios.get(API_URL + "units/");
  }
  createUnit(form) {
    return axios.post(API_URL + "units/", form);
  }
  editUnit(form) {
    return axios.put(API_URL + `units/${form.id}/`, form);
  }
  deleteUnit(unitId) {
    return axios.delete(API_URL + `units/${unitId}/`);
  }
  createContact(form) {
    return axios.post(API_URL + "contacts/", form);
  }
  deleteContact(contactId) {
    return axios.delete(API_URL + `contacts/${contactId}/`);
  }
  closeUnitTask(taskId) {
    return axios.patch(API_URL + `tasks/${taskId}/`, { completed: true });
  }
  fetchTasksHistory(unitId) {
    return axios.get(API_URL + `tasks/history/${unitId}/`);
  }
  fetchResponsibles() {
    return axios.get(API_URL + "tasks/responsibles/");
  }
  editResponsible({ taskId, form }) {
    return axios.patch(API_URL + `tasks/${taskId}/responsibles/`, form);
  }
}

export default new MaintenanceService();
