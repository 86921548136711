<template>
  <div class="specification__container">
    <el-tooltip placement="right">
      <template #content>
        <div class="specification__example received">Получено</div>
        <div class="specification__example">Не получено</div>
        <div class="specification__example unbind">Не привязано</div>
        <div class="specification__example binding">В процессе привязки</div>
        <div>
          <el-icon><Link /></el-icon>
          Привязано к текущему счету
        </div>
        <div>
          <el-icon><ChatSquare /></el-icon>
          Комментарий
        </div>
      </template>
      <span class="specification__container-title">
        <span style="vertical-align: middle"> Список оборудования </span>
        <el-icon style="vertical-align: middle"><QuestionFilled /></el-icon>
      </span>
    </el-tooltip>
    - {{ `позиции ${specifications.length}` }}
    <el-row v-if="specifications.length">
      <el-input
        v-model="search"
        placeholder="Поиск по названию..."
        style="margin-bottom: 10px"
        aria-errormessage="none"
        clearable
      />
    </el-row>
    <div class="specification__list" v-if="specifications.length">
      <div
        class="specification__item"
        v-for="specification in filteredSpecifications"
        :key="specification"
        :title="specification.name"
        :class="[
          specification?.invoice ? specification?.is_received && 'received' : 'unbind',
          isSpecificationBinded(specification) && 'binded'
        ]"
      >
        <div class="specification__item-text">
          {{ `${specification.count} - ${specification.name}` }}
        </div>
        <div class="specification__item-icons">
          <span v-if="specification?.comment.length" class="specification__item-icon">
            <el-tooltip placement="top">
              <template #content>{{ specification.comment }}</template>
              <el-icon><ChatSquare /></el-icon>
            </el-tooltip>
          </span>
          <span v-if="removeMethod" class="specification__item-icon clickable" @click="removeMethod(specification)">
            <el-icon><Delete /></el-icon>
          </span>
          <span
            v-if="bindMethod && !specification.invoice && !isSpecificationBinded(specification)"
            class="specification__item-icon clickable"
            @click="bindSpecification(specification)"
          >
            <el-icon><CirclePlusFilled /></el-icon>
          </span>
          <span
            v-else-if="unBindMethod && isSpecificationBinded(specification)"
            class="specification__item-icon clickable"
            @click="unBindSpecification(specification)"
          >
            <el-icon><RemoveFilled /></el-icon>
          </span>
          <span v-if="invoice && invoice == specification.invoice" class="specification__item-icon">
            <el-icon><Link /></el-icon>
          </span>
        </div>
      </div>
    </div>
    <div class="specification__empty" v-else>Список пуст</div>
  </div>
</template>

<script>
import { isStrInSearch } from "@/utils/utils";

export default {
  name: "res-specification",
  props: {
    specifications: { type: Array, required: false, default: new Array() },
    bindMethod: { type: Function, required: false, default: null },
    unBindMethod: { type: Function, required: false, default: null },
    removeMethod: { type: Function, required: false, default: null },
    invoice: { type: Number, required: false, default: null }
  },
  data() {
    return {
      bindedItems: [],
      search: ""
    };
  },
  methods: {
    bindSpecification(specification) {
      this.bindedItems.push(specification);
      this.bindMethod(specification);
    },
    unBindSpecification(specification) {
      this.bindedItems = this.bindedItems.filter(item => item !== specification);
      this.unBindMethod(specification);
    },
    isSpecificationBinded(specification) {
      return this.bindedItems.find(item => item === specification);
    }
  },
  computed: {
    filteredSpecifications() {
      return [...this.specifications]
        .sort((a, b) => a.invoice && -1)
        .sort((a, b) => this.invoice == a.invoice && -1)
        .sort((a, b) => a.is_received && 1)
        .filter(item => isStrInSearch(item?.name, this.search));
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/mixins";

$specifications-min-height: 37px * 5;
$specification-items-height: 37px * 11;

.specification {
  &__example {
    position: relative;
    padding-left: 18px;

    &::before {
      position: absolute;
      content: "";
      top: 50%;
      left: 3px;
      transform: translateY(-50%);
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: var(--red);

      transition: all 0.2s ease;
    }

    &.received::before {
      background: var(--green);
    }
    &.unbind::before {
      background: rgba(202, 202, 202, 0.829);
    }
    &.binding::before {
      background: var(--yellow);
    }
  }

  &__container {
    width: 100%;
    height: 100%;

    &-title {
      line-height: 32px;
      font-size: 16px;
    }
  }

  &__empty {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: $specifications-min-height;
    max-height: $specification-items-height;
    user-select: none;
  }

  &__list {
    min-height: $specifications-min-height;
    max-height: $specification-items-height;
    overflow: auto;
    padding-right: 7px;
    @include scroll(7px, var(--scroll-thumb), var(--scroll-track));
  }

  &__item {
    display: flex;
    justify-content: space-between;
    position: relative;
    background: rgba(#747481, 0.1);
    border-radius: 3px;
    padding: 5px 10px 5px 25px;

    &-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: all 0.3s ease;
    }

    &::before {
      position: absolute;
      content: "";
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: var(--red);
      transition: all 0.2s ease;
    }

    &.unbind::before {
      background: rgba(202, 202, 202, 0.829);
    }

    &.received::before {
      background: var(--green);
    }

    &.binded::before {
      background: var(--yellow);
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &:hover {
      filter: brightness(1.1);
    }

    &-icons {
      display: flex;
    }

    &-icon {
      &.clickable {
        cursor: pointer;

        &:active {
          filter: brightness(0.9);
        }
      }
    }
    &-icon + &-icon {
      margin-left: 5px;
    }
  }
}
</style>
