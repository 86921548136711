import axios from 'axios'
import { apiClient } from './axiosAdapter'

const API_URL = '/api/'

class AuthService {
  login(user) {
    return axios.post(API_URL + 'login/', { username: user.username, password: user.password }).then((response) => {
      if (response.data.access) localStorage.setItem('user', JSON.stringify(response.data))
      return response.data
    })
  }
  logout(refresh_token) {
    return apiClient.post('logout/', { refresh_token })
  }
  changePassword(form) {
    return apiClient.put('change_password/', form)
  }
  updateUserInfo() {
    return apiClient.get('user/')
  }
  updateRefreshToken(form) {
    return apiClient.post('token/refresh/', form)
  }
}

export default new AuthService()
