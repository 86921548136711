import { apiClient as axios } from './axiosAdapter'

const API_URL = 'ats/'

class AtsService {
  fetchExams(params) {
    return axios.get(`${API_URL}exams/`, { params })
  }
  deleteExam(ExamId) {
    return axios.delete(`${API_URL}exams/${ExamId}`)
  }
  editExam(formData) {
    return axios.put(`${API_URL}exams/update/${formData.id}/`, formData)
  }
  editExamResult(formData) {
    return axios.put(`${API_URL}exams/update/${formData.id}/`, formData)
  }
  addExam(formData) {
    return axios.post('/ats/exams/add/', formData)
  }
  fetchResultCount() {
    return axios.get('/ats/exam_results_stats/')
  }
}

export default new AtsService()
