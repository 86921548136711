import MaintenanceService from "@/services/maintenance.service";

const initialState = {
  loading: false,
  units: []
};

export const maintenance = {
  namespaced: true,
  state: initialState,
  actions: {
    fetchUnits({ commit }) {
      commit("changeLoading", true);
      return MaintenanceService.fetchUnits().then(
        units => {
          commit("fetchUnitsSuccess", units);
          return Promise.resolve(units);
        },
        error => {
          commit("fetchUnitsFailure");
          return Promise.reject(error);
        }
      );
    },
    createUnit({ commit, dispatch }, form) {
      commit("changeLoading", true);
      return MaintenanceService.createUnit(form).then(
        data => {
          commit("changeLoading", false);
          commit("setMessage", { text: "createMaintenanceUnitSuccess", type: "success" }, { root: true });
          dispatch("fetchUnits");
          return Promise.resolve(data);
        },
        error => {
          commit("changeLoading", false);
          return Promise.reject(error);
        }
      );
    },
    editUnit({ commit, dispatch }, form) {
      commit("changeLoading", true);
      return MaintenanceService.editUnit(form).then(
        data => {
          commit("changeLoading", false);
          commit("setMessage", { text: "editMaintenanceUnitSuccess", type: "success" }, { root: true });
          dispatch("fetchUnits");
          return Promise.resolve(data);
        },
        error => {
          commit("changeLoading", false);
          return Promise.reject(error);
        }
      );
    },
    deleteUnit({ commit, dispatch }, unitId) {
      commit("changeLoading", true);
      return MaintenanceService.deleteUnit(unitId).then(
        data => {
          commit("changeLoading", false);
          commit("setMessage", { text: "deleteMaintenanceUnitSuccess", type: "success" }, { root: true });
          dispatch("fetchUnits");
          return Promise.resolve(data);
        },
        error => {
          commit("changeLoading", false);
          return Promise.reject(error);
        }
      );
    },
    createContact({ commit, dispatch }, form) {
      commit("changeLoading", true);
      return MaintenanceService.createContact(form).then(
        data => {
          commit("changeLoading", false);
          commit("setMessage", { text: "createMaintenanceContactSuccess", type: "success" }, { root: true });
          dispatch("fetchUnits");
          return Promise.resolve(data);
        },
        error => {
          commit("changeLoading", false);
          return Promise.reject(error);
        }
      );
    },
    deleteContact({ commit, dispatch }, contactId) {
      commit("changeLoading", true);
      return MaintenanceService.deleteContact(contactId).then(
        data => {
          commit("changeLoading", false);
          commit("setMessage", { text: "deleteMaintenanceContactSuccess", type: "success" }, { root: true });
          dispatch("fetchUnits");
          return Promise.resolve(data);
        },
        error => {
          commit("changeLoading", false);
          return Promise.reject(error);
        }
      );
    },
    closeUnitTask({ commit, dispatch }, taskId) {
      commit("changeLoading", true);
      return MaintenanceService.closeUnitTask(taskId).then(
        data => {
          commit("changeLoading", false);
          commit("setMessage", { text: "closeMaintenanceTaskSuccess", type: "success" }, { root: true });
          dispatch("fetchUnits");
          return Promise.resolve(data);
        },
        error => {
          commit("changeLoading", false);
          return Promise.reject(error);
        }
      );
    },
    editResponsible({ commit, dispatch }, data) {
      commit("changeLoading", true);
      return MaintenanceService.editResponsible(data).then(
        data => {
          commit("changeLoading", false);
          dispatch("fetchUnits");
          return Promise.resolve(data);
        },
        error => {
          commit("changeLoading", false);
          return Promise.reject(error);
        }
      );
    }
  },
  mutations: {
    changeLoading(state, status) {
      state.loading = status;
    },
    fetchUnitsSuccess(state, units) {
      state.loading = false;
      state.units = units;
    },
    fetchUnitsFailure(state) {
      state.loading = false;
      state.units = [];
    }
  }
};
