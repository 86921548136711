const hasGroup = (state, group) => state.user?.groups?.includes(group) || false

export const isContractor = (state) => hasGroup(state, 'contractor')
export const isContractorAdmin = (state) => hasGroup(state, 'contractorAdmin')
export const isContractorEditor = (state) => hasGroup(state, 'contractorEditor')
export const isContractorRemover = (state) => hasGroup(state, 'contractorRemover')

export const isContractorPika = (state) => hasGroup(state, 'contractorPika')
export const isContractorPikaAdmin = (state) => hasGroup(state, 'contractorPikaAdmin')

export const isAccounterAdmin = (state) => hasGroup(state, 'accounterAdmin')
export const isAccounterAgreeder = (state) => hasGroup(state, 'accounterAgreeder')
export const isAccounterEditor = (state) => hasGroup(state, 'accounterEditor')
export const isAccounterRemover = (state) => hasGroup(state, 'accounterRemover')

export const isWorkloadEditor = (state) => hasGroup(state, 'workloadEditor')
export const isWorkloadAgreeder = (state) => hasGroup(state, 'workloadAgreeder')
export const isWorkloadConfirmer = (state) => hasGroup(state, 'workloadConfirmer')

export const isLectures = (state) => hasGroup(state, 'lectures')
export const lecturesViewer = (state) => hasGroup(state, 'lecturesViewer')
export const isLecturesAdmin = (state) => hasGroup(state, 'lecturesAdmin')

export const isRemouteAccess = (state) => hasGroup(state, 'remouteaccess')
export const isRemouteAccessAdmin = (state) => hasGroup(state, 'remouteaccessAdmin')
