import store from "../../store";
import * as types from "../../store/auth/mutations_types";
import authHeader from "../auth.header";
import { dowloadFileFromResponse, watchFileFromResponse, getErrorMessage } from "../utils";

export const dataUnpackHandler = response => response.data;
export const dataFileHandler = response => dowloadFileFromResponse(response);
export const dataFileWatchHandler = response => watchFileFromResponse(response);

const validateErrorWithConstraints = async (error, refreshCount) => {
  const breakCodes = ["user_inactive"];
  const isRefreshExpired = error.config?.url?.includes("refresh") && error.response?.data?.code == "token_not_valid";
  if (
    (refreshCount > 3 && error.response.status === 401) ||
    breakCodes.includes(error.response?.data?.code) ||
    isRefreshExpired
  ) {
    await store.commit(`auth/${types.LOGOUT}`);
    location.reload();
  }
};

const fetchNewAccessTokenAndRetryCall = async (client, error) => {
  const callingRefreshCount = store.state.auth.status.callingRefreshCount;
  const refreshToken = { refresh: store.state.auth.user.refresh };
  let callingRefreshFn = store.state.auth.status.callingRefresh;

  await validateErrorWithConstraints(error, callingRefreshCount);

  if (!callingRefreshFn && callingRefreshCount < 3) {
    callingRefreshFn = store.dispatch("auth/updateRefreshToken", refreshToken);
  }
  await callingRefreshFn;
  return client.request(error.config);
};

export const errorHandlerFactory = client => async error => {
  if (error.response.status === 401 && store.state.auth.user) return fetchNewAccessTokenAndRetryCall(client, error);
  else if (error.response.status === 403 && store.state.auth.user) store.dispatch("auth/updateUserInfo");
  store.commit("setMessage", { text: getErrorMessage(error), type: "error" });
  return Promise.reject(error);
};

export const authHandlerFactory = ({ includeBlob }) => config => {
  config.headers = { ...config.headers, ...authHeader() };
  if (includeBlob) config.responseType = "blob";
  return config;
};

export const authHandler = authHandlerFactory({ includeBlob: false });
export const authFileHandler = authHandlerFactory({ includeBlob: true });
