import * as types from "./mutations_types";

export default {
  [types.FETCH_CUSTOMERS](state) {
    state.isLoading = true;
  },
  [types.FETCH_CUSTOMERS_SUCCESS](state, response) {
    state.isLoading = false;
    state.customers = response.results;
    state.totalCustomers = response.total;
  },
  [types.FETCH_CUSTOMERS_ERROR](state) {
    state.isLoading = false;
    state.customers = [];
  },
  [types.DELETE_CUSTOMER](state) {
    state.isLoading = true;
  },
  [types.DELETE_CUSTOMER_SUCCESS](state) {
    state.isLoading = false;
  },
  [types.DELETE_CUSTOMER_ERROR](state) {
    state.isLoading = false;
  }
};
