import actions from "./actions";
import * as getters from "./getters";
import mutations from "./mutations";

const initialState = {
  loading: false,
  contractsTotal: 0,
  contracts: []
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters
};
