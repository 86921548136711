import LecturesService from '@/services/lectures.service'

const initialState = {
  isLoading: false,
  scrollLeft: 0,
  scrollTop: 0,
  themes: []
}

export const lectures = {
  namespaced: true,
  state: initialState,
  actions: {
    fetchThemes({ commit }) {
      commit('changeLoading', true)
      return LecturesService.fetchLecturesThemes().then(
        (themes) => {
          commit('fetchThemesSuccess', themes)
          return Promise.resolve(themes)
        },
        (error) => {
          commit('fetchThemesFailure')
          return Promise.reject(error)
        }
      )
    },
    setScrollLeft({ commit }, pos) {
      commit('setScrollLeft', pos)
    },
    setScrollTop({ commit }, pos) {
      commit('setScrollTop', pos)
    }
  },
  mutations: {
    changeLoading(state, status) {
      state.isLoading = status
    },
    fetchThemesSuccess(state, themes) {
      state.isLoading = false
      state.themes = themes
    },
    fetchThemesFailure(state) {
      state.isLoading = false
      state.themes = []
    },
    setScrollLeft(state, pos) {
      state.scrollLeft = pos
    },
    setScrollTop(state, pos) {
      state.scrollTop = pos
    }
  },
  getters: {
    isLoading(state) {
      return state.isLoading
    },
    scrollLeft(state) {
      return state.scrollLeft
    },
    scrollTop(state) {
      return state.scrollTop
    },
    themes(state) {
      return state.themes
    }
  }
}
