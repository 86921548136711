<template>
  <div class="tree">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'res-tree'
}
</script>

<style lang="scss" scoped>
.tree {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
</style>
