<template>
  <input @change="onChangeFileUpload()" ref="file" type="file" name="file" class="inputfile" />
  <label for="file" class="res-upload" @click="openUpload">
    <slot>{{ file ? "Файл загружен" : "Загрузка файла" }}</slot>
  </label>
  <!-- <span v-if="file" class="test">{{ file?.name }}</span> -->
</template>

<script>
export default {
  name: "res-upload",
  emits: ["fileChanged"],
  props: {
    file: { required: false }
  },
  methods: {
    onChangeFileUpload() {
      const uploadedFile = this.$refs.file.files[0];
      this.$emit("fileChanged", uploadedFile ?? null);
    },
    openUpload() {
      this.$refs.file.value = "";
      this.$refs.file.click();
    }
  }
};
</script>

<style lang="scss" scoped>
.inputfile {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  display: none;
  z-index: -1;
  font-weight: 600;

  & + label {
    color: var(--text);
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 3px;
    padding: 0 10px;
    line-height: 30px;
    background: var(--el-bg-color-overlay);
    border: 1px solid var(--input-border);
    display: inline-block;
    cursor: pointer;
  }

  &:focus + label,
  & + label:hover {
    filter: brightness(1.1);
  }

  & + label:active {
    filter: brightness(1);
  }

  & + label + span {
    margin-left: 10px;
  }
}
</style>
