import actions from "./actions";
import * as getters from "./getters";
import mutations from "./mutations";

const initialState = {
  isLoading: false,
  customers: [],
  totalCustomers: 0
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters
};
