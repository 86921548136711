import { ElMessage } from 'element-plus'

export const getDaysAgoDate = (days) => new Date(new Date().setDate(new Date().getDate() - days))

const getDateParts = (date) => {
  let day = ('0' + date.getDate()).slice(-2)
  let month = ('0' + (date.getMonth() + 1)).slice(-2)
  let year = date.getFullYear()
  return [day, month, year]
}

export const formatDate = (date) => {
  const [day, month, year] = getDateParts(date)
  return `${year}-${month}-${day}`
}

export const formatDateWithDots = (date) => {
  const [day, month, year] = getDateParts(date)
  return `${day}.${month}.${year}`
}

export const getYesterdayDate = () => {
  let now = new Date()
  now.setDate(now.getDate() - 1)
  return now
}

export const getTomorrowDate = () => {
  let now = new Date()
  now.setDate(now.getDate() + 1)
  return now
}

export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const isStrInSearch = (str, search) => {
  return typeof str == 'string' ? str.toLowerCase().includes(search.toLowerCase()) : false
}

export const isStrInSearchFlag = (str, search, flag) => {
  return typeof str == 'string' ? str.toLowerCase().includes(search.toLowerCase()) : false
}

export const formatCurrency = (currency) => {
  return Number(Number(currency).toFixed(2))
    .toLocaleString('ru-Ru', {
      style: 'currency',
      currency: 'RUB'
    })
    .replace(',', '.')
}

export const formatNumber = (number, postfix = '') => {
  return `${number.toLocaleString('ru-Ru')} ${postfix}`
}

export const valueWithPostfixFormatter = (rowData, rowCol, postfix = '') => {
  return `${rowData[rowCol.property]} ${postfix}`
}

export const parseDate = (str) => {
  const [day, month, year] = str.split('.')
  return new Date(`20${year}-${month}-${day}`)
}

export const watchFile = (url) => {
  const link = document.createElement('a')
  link.href = url
  link.target = '_blank'
  link.click()
}

export const formatEmptyColumn = (row, col) => {
  const cellValue = col.property.split('.').reduce((prev, next) => prev[next], row)
  return cellValue ? cellValue : '-'
}

export const formatCurrencyColumn = (row, col) => {
  return formatCurrency(row[col.property])
}

export const formatNumberColumn = (row, col) => {
  return formatNumber(row[col.property])
}

export const zip = (a, b) => {
  const zipped = {}
  a.forEach((k, i) => (zipped[k] = b[i]))
  return zipped
}

export const pluralValueWord = (value, words) => {
  value = Math.abs(value) % 100
  const num = value % 10
  if (value > 10 && value < 20) return words[2]
  if (num > 1 && num < 5) return words[1]
  if (num == 1) return words[0]
  return words[2]
}

export const getSortStatus = (column) => {
  if (column.prop !== null) {
    const preparedProp = column.order.includes('ascending') ? `-${column.prop}` : column.prop
    return preparedProp
  } else {
    return ''
  }
}

export const getStudents = (students) => {
  let array = []
  for (let i = 0; i < students.length; i++) {
    if (!students[i].is_viewer) array.push(students[i])
  }
  return array
}

export const copyText = (text) => {
  let textArea = document.createElement('textarea')
  textArea.value = text
  textArea.style.position = 'fixed'
  textArea.style.left = '-999999px'
  textArea.style.top = '-999999px'
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()
  return new Promise((res, rej) => {
    document.execCommand('copy') ? res() : rej()
    ElMessage({ message: 'Текст скопирован', type: 'success' })
    textArea.remove()
  })
}
