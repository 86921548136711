import * as types from './mutations_types'
import WorkloadService from '../../services/workload.service'

export default {
  setActiveRow({ commit }, id) {
    commit(types.SET_ACTIVE_ROW, id)
  },
  setScrollTop({ commit }, pos) {
    commit(types.SET_SCROLL_TOP, pos)
  },
  setScrollLeft({ commit }, pos) {
    commit(types.SET_SCROLL_LEFT, pos)
  },
  fetchWorkload({ commit, dispatch }, params) {
    commit(types.FETCH_WORKLOAD)
    return WorkloadService.fetchWorkload(params)
      .then(async (workload) => {
        await dispatch('fetchWorkloadTags', { year: params.year })
        await dispatch('fetchWorkloadObjectsPto', { year: params.year })
        commit(types.FETCH_WORKLOAD_SUCCESS, workload)
        return Promise.resolve(workload)
      })
      .catch((error) => {
        commit(types.FETCH_WORKLOAD_ERROR)
        return Promise.reject(error)
      })
  },
  fetchWorkloadObjectsPto({ commit, dispatch }, params) {
    commit(types.FETCH_WORKLOAD_OBJECT_PTO)
    return WorkloadService.fetchWorkloadPtoObjects(params)
      .then((objectsPto) => {
        commit(types.FETCH_WORKLOAD_OBJECT_PTO_SUCCESS, objectsPto)
        return Promise.resolve(objectsPto)
      })
      .catch((error) => {
        commit(types.FETCH_WORKLOAD_OBJECT_PTO_ERROR)
        return Promise.reject(error)
      })
  },
  createObjectsPto({ commit }, form) {
    return WorkloadService.createPtoObjects(form).then(() => {
      commit('setMessage', { text: 'createWorkloadPtoSuccess', type: 'success' }, { root: true })
      return Promise.resolve()
    })
  },
  updateObjectsPto({ commit }, { objectId, form }) {
    return WorkloadService.updatePtoObjects(objectId, form).then((task) => {
      commit('setMessage', { text: 'editWorkloadPtoSuccess', type: 'success' }, { root: true })
      return Promise.resolve(task)
    })
  },
  deleteObjectsPto({ commit, dispatch }, { year, objectId }) {
    return WorkloadService.deletePtoObjects(objectId).then(async () => {
      commit('setMessage', { text: 'deleteWorkloadPtoSuccess', type: 'success' }, { root: true })
      await dispatch('fetchWorkloadObjectsPto', { year: year })
      return Promise.resolve()
    })
  },
  createPtoTask({ commit }, form) {
    return WorkloadService.createPtoTask(form).then((task) => {
      commit('setMessage', { text: 'createWorkloadTaskPto', type: 'success' }, { root: true })
      return Promise.resolve(task)
    })
  },
  updatePtoTask({ commit }, { taskId, form }) {
    return WorkloadService.updatePtoTask(taskId, form).then((task) => {
      commit('setMessage', { text: 'changeWorkloadStatusTaskPto', type: 'success' }, { root: true })
      return Promise.resolve(task)
    })
  },
  deletePtoTask({ commit, dispatch }, { year, taskId }) {
    return WorkloadService.deletePtoTask(taskId).then(async (task) => {
      commit('setMessage', { text: 'deleteWorkloadStatusTaskPto', type: 'success' }, { root: true })
      await dispatch('fetchWorkloadObjectsPto', { year: year })
      return Promise.resolve(task)
    })
  },
  fetchWorkloadTags({ commit }, params) {
    commit(types.FETCH_WORKLOAD_TAGS)
    return WorkloadService.fetchWorkloadTags(params)
      .then((tags) => {
        commit(types.FETCH_WORKLOAD_TAGS_SUCCESS, tags)
        return Promise.resolve(tags)
      })
      .catch((error) => {
        commit(types.FETCH_WORKLOAD_TAGS_ERROR)
        return Promise.reject(error)
      })
  },
  createTag({ commit }, form) {
    return WorkloadService.createTag(form).then((tag) => {
      commit('setMessage', { text: 'createTagSuccess', type: 'success' }, { root: true })
      return Promise.resolve(tag)
    })
  },
  editTag({ commit }, { tagId, form }) {
    return WorkloadService.editTag(tagId, form).then((tag) => {
      commit('setMessage', { text: 'editTagSuccess', type: 'success' }, { root: true })
      return Promise.resolve(tag)
    })
  },
  deleteTag({ commit }, tagId) {
    return WorkloadService.deleteTag(tagId).then(() => {
      commit('setMessage', { text: 'deleteTagSuccess', type: 'success' }, { root: true })
      return Promise.resolve()
    })
  },
  createUnit({ commit }, form) {
    return WorkloadService.createUnit(form).then(() => {
      commit('setMessage', { text: 'createWorkloadSuccess', type: 'success' }, { root: true })
      return Promise.resolve()
    })
  },
  editUnit({ commit }, { unitId, form }) {
    return WorkloadService.editUnit(unitId, form).then(() => {
      commit('setMessage', { text: 'editWorkloadSuccess', type: 'success' }, { root: true })
      return Promise.resolve()
    })
  },
  deleteUnit({ commit }, unitId) {
    return WorkloadService.deleteUnit(unitId).then(() => {
      commit('setMessage', { text: 'deleteWorkloadSuccess', type: 'success' }, { root: true })
      return Promise.resolve()
    })
  },
  createTask({ commit }, form) {
    return WorkloadService.createTask(form).then(() => {
      commit('setMessage', { text: 'createWorkloadTaskSuccess', type: 'success' }, { root: true })
      return Promise.resolve()
    })
  },
  deleteTask({ commit }, taskId) {
    return WorkloadService.deleteTask(taskId).then(() => {
      commit('setMessage', { text: 'deleteWorkloadTaskSuccess', type: 'success' }, { root: true })
      return Promise.resolve()
    })
  },
  editTask({ commit }, { taskId, form }) {
    return WorkloadService.editTask(taskId, form).then(() => {
      commit('setMessage', { text: 'editWorkloadTaskSuccess', type: 'success' }, { root: true })
      return Promise.resolve()
    })
  },
  deleteTaskFile({ commit }, fileId) {
    return WorkloadService.deleteTaskFile(fileId).then(() => {
      commit('setMessage', { text: 'deleteWorkloadTaskFileSuccess', type: 'success' }, { root: true })
      return Promise.resolve()
    })
  },
  createTaskFile(_, form) {
    return WorkloadService.createTaskFile(form)
  },
  changeTaskAgreed({ commit }, taskId) {
    return WorkloadService.changeTaskAgreed(taskId).then(() => {
      commit('setMessage', { text: 'changeWorkloadTaskAgreedSuccess', type: 'success' }, { root: true })
      return Promise.resolve()
    })
  },
  changeTaskConfirmation({ commit }, taskId) {
    return WorkloadService.changeTaskConfirmation(taskId).then(() => {
      commit('setMessage', { text: 'changeWorkloadTaskConfirmationSuccess', type: 'success' }, { root: true })
      return Promise.resolve()
    })
  }
}
