import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faLock,
  faSpinner,
  faTimes,
  faSync,
  faEnvelope,
  faWrench,
  faCog,
  faFolderMinus,
  faFolderPlus,
  faSave,
  faRedo,
  faPlusCircle,
  faArrowLeft,
  faArrowRight,
  faProjectDiagram,
  faSearch,
  faBell,
  faBolt,
  faCar,
  faMapMarkerAlt,
  faMicrochip,
  faCarCrash,
  faCarAlt,
  faSuitcase,
  faBars,
  faAddressBook,
  faCakeCandles,
  faChalkboardUser,
  faListCheck,
  faUserTie
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faLock,
  faSpinner,
  faTimes,
  faSync,
  faEnvelope,
  faWrench,
  faCog,
  faPlusCircle,
  faFolderMinus,
  faFolderPlus,
  faSave,
  faRedo,
  faArrowLeft,
  faArrowRight,
  faProjectDiagram,
  faSearch,
  faBell,
  faBolt,
  faCar,
  faMapMarkerAlt,
  faMicrochip,
  faCarCrash,
  faCarAlt,
  faSuitcase,
  faBars,
  faAddressBook,
  faCakeCandles,
  faChalkboardUser,
  faListCheck,
  faUserTie
)

export default FontAwesomeIcon
