<template>
  <div class="page-header">
    <el-page-header @back="$router.push(pushParams)">
      <template #title>
        <div class="back-text">Назад</div>
      </template>
      <template v-if="title" #content>
        <div class="back-text">{{ title }}</div>
      </template>
    </el-page-header>
    <slot name="extra" />
  </div>
</template>
<script>
export default {
  name: 'res-page-header',
  props: { pushParams: { type: Object, required: true }, title: { type: String, required: false } }
}
</script>
<style lang="scss" scoped>
.back-text {
  height: 100%;
  display: flex;
  align-items: center;
}

.page-header {
  width: 100%;
  padding: 3px 5px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
</style>
