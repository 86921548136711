import CustomersService from "@/services/customers.service";
import * as types from "./mutations_types";

export default {
  fetchCustomers({ commit }, params) {
    commit(types.FETCH_CUSTOMERS);
    return CustomersService.fetchCustomers(params).then(
      data => {
        commit(types.FETCH_CUSTOMERS_SUCCESS, data);
        return Promise.resolve(data);
      },
      error => {
        commit(types.FETCH_CUSTOMERS_ERROR);
        return Promise.reject(error);
      }
    );
  },
  createCustomer({ commit }, form) {
    return CustomersService.createCustomer(form).then(() => {
      commit("setMessage", { text: "createCustomerSuccess", type: "success" }, { root: true });
      return Promise.resolve();
    });
  },
  editCustomer({ commit }, { customerId, form }) {
    return CustomersService.editCustomer(customerId, form).then(() => {
      commit("setMessage", { text: "editCustomerSuccess", type: "success" }, { root: true });
      return Promise.resolve();
    });
  },
  deleteCustomer({ commit }, customerId) {
    commit(types.DELETE_CUSTOMER);
    return CustomersService.deleteCustomer(customerId).then(
      () => {
        commit(types.DELETE_CUSTOMER_SUCCESS);
        commit("setMessage", { text: "deleteCustomerSuccess", type: "success" }, { root: true });
        return Promise.resolve();
      },
      error => {
        commit(types.DELETE_CUSTOMER_ERROR);
        return Promise.reject(error);
      }
    );
  }
};
