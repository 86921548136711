import { apiClient as axios } from "./axiosAdapter";

const API_URL = "contracts/customers/";

class CustomerService {
  fetchCustomers(params) {
    return axios.get(API_URL, { params });
  }
  createCustomer(form) {
    return axios.post(API_URL, form);
  }
  editCustomer(customerId, form) {
    return axios.patch(`${API_URL}${customerId}/`, form);
  }
  deleteCustomer(customerId) {
    return axios.delete(`${API_URL}${customerId}/`);
  }
}

export default new CustomerService();
