import * as types from './mutations_types'
import { scrollTop } from './getters'

export default {
  [types.FETCH_WORKLOAD](state) {
    state.isLoading = true
  },
  [types.FETCH_WORKLOAD_SUCCESS](state, workload) {
    state.workload = workload
    state.isLoading = false
  },
  [types.FETCH_WORKLOAD_ERROR](state) {
    state.workload = []
    state.isLoading = false
  },
  [types.FETCH_WORKLOAD_TAGS](state) {
    state.isLoading = true
  },
  [types.FETCH_WORKLOAD_TAGS_SUCCESS](state, tags) {
    state.tags = tags
  },
  [types.FETCH_WORKLOAD_TAGS_ERROR](state) {
    state.tags = []
  },
  [types.SET_ACTIVE_ROW](state, id) {
    state.activeId = id
  },
  [types.SET_SCROLL_TOP](state, pos) {
    state.scrollTop = pos
  },
  [types.SET_SCROLL_LEFT](state, pos) {
    state.scrollLeft = pos
  },
  [types.FETCH_WORKLOAD_OBJECT_PTO](state) {
    state.isLoadingPto = true
  },
  [types.FETCH_WORKLOAD_OBJECT_PTO_SUCCESS](state, objects) {
    state.objectsPto = objects
    state.isLoadingPto = false
  },
  [types.FETCH_WORKLOAD_OBJECT_PTO_ERROR](state) {
    state.objectsPto = []
    state.isLoadingPto = false
  }
}
