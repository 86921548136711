import * as types from "./mutations_types";

export default {
  [types.FETCH_CONTRACTS](state) {
    state.loading = true;
  },
  [types.FETCH_CONTRACTS_SUCCESS](state, data) {
    state.loading = false;
    state.contracts = data.results;
    state.contractsTotal = data.total;
  },
  [types.FETCH_CONTRACTS_ERROR](state) {
    state.loading = false;
    state.contracts = [];
  }
};
