<template>
  <input
    :class="[type == 'file' ? 'custom-file-input' : 'input']"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value.trim())"
    :type="type"
  />
</template>

<script>
export default {
  name: 'res-input',
  props: {
    modelValue: [String, Number],
    type: { type: String, required: false, default: 'text' }
  }
}
</script>

<style lang="scss" scoped>
.input {
  width: 100%;
  outline: none;
  height: 30px;
  padding: 15px 10px;
  border: 1px solid var(--input-border);
  background: var(--input-bg);
  color: #fff;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 400;

  &:focus {
    border: 1px solid var(--input-border-focus);
  }

  &:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0 50px var(--input-bg) !important;
    -webkit-text-fill-color: #fff;
  }
}
.custom-file-input {
  display: flex;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  color: var(--text-2);
  cursor: pointer;
  border-radius: 3px;
  padding: 5px 10px;
  border: 1px solid var(--input-border);
  background: var(--input-bg);

  &::-webkit-file-upload-button {
    visibility: hidden;
  }
  &::before {
    content: 'Выберите файл';
    display: inline-block;
    position: absolute;
    top: 50%;
    padding-right: 10px;
    border-right: 1px solid var(--input-border);
    transform: translateY(-50%);
  }

  &:focus {
    border: 1px solid var(--input-border-focus);
  }
}
</style>
