import ResButton from "@/components/UI/ResButton.vue";
import ResNav from "@/components/UI/ResNav.vue";
import ResInput from "@/components/UI/ResInput.vue";
import ResCard from "@/components/UI/ResCard.vue";
import ResModal from "@/components/UI/ResModal.vue";
import ResTree from "@/components/UI/ResTree.vue";
import TreeItem from "@/components/UI/ResTreeItem.vue";
import ResCheckbox from "@/components/UI/ResCheckbox.vue";
import ResUpload from "@/components/UI/ResUpload.vue";
import ResSpecification from "@/components/UI/ResSpecification.vue";
import ResUploadV2 from "@/components/UI/ResUploadV2.vue";
import ResPageHeader from "@/components/UI/ResPageHeader.vue";
import ResList from "@/components/UI/ResList.vue";

export default [
  ResButton,
  ResInput,
  ResNav,
  ResCard,
  ResModal,
  ResTree,
  TreeItem,
  ResCheckbox,
  ResUpload,
  ResUploadV2,
  ResPageHeader,
  ResList,
  ResSpecification
];
