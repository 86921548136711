import CarsService from '@/services/cars.service'

const initialState = {
  loading: false,
  cars: [],
  techEventsTypes: [],
  tripEventsTypes: []
}

export const cars = {
  namespaced: true,
  state: initialState,
  actions: {
    loadCars({ commit }) {
      commit('carsLoading', true)
      return CarsService.loadCars().then(
        (data) => {
          commit('carsLoadSuccess', data)
          return Promise.resolve(data)
        },
        (error) => {
          commit('carsLoadFailed')
          return Promise.reject(error)
        }
      )
    },
    updateCarInfo({ commit }, form) {
      return CarsService.updateCarInfo(form).then((response) => {
        commit('setMessage', { text: 'carInfoUpdateSuccess', type: 'success' }, { root: true })
        return Promise.resolve(response)
      })
    },
    addCarTechEvent({ commit }, form) {
      return CarsService.addCarTechEvent(form).then((response) => {
        commit('setMessage', { text: 'carEventAddSuccess', type: 'success' }, { root: true })
        return Promise.resolve(response)
      })
    },
    updateCarTechEvent({ commit }, form) {
      return CarsService.updateCarTechEvent(form).then((response) => {
        commit('setMessage', { text: 'carEventUpdateSuccess', type: 'success' }, { root: true })
        return Promise.resolve(response)
      })
    },
    removeCarTechEvent({ commit }, id) {
      return CarsService.removeCarTechEvent(id).then((response) => {
        commit('setMessage', { text: 'carEventRemoveSuccess', type: 'success' }, { root: true })
        return Promise.resolve(response)
      })
    },
    removeCarComplaint({ commit }, complaintId) {
      return CarsService.removeCarComplaint(complaintId).then((response) => {
        commit('setMessage', { text: 'carComplaintRemoveSuccess', type: 'success' }, { root: true })
        return Promise.resolve(response)
      })
    },
    addCarComplaint({ commit }, form) {
      return CarsService.addCarComplaint(form).then((response) => {
        commit('setMessage', { text: 'carComplaintAddSuccess', type: 'success' }, { root: true })
        return Promise.resolve(response)
      })
    },
    addCarTripEvent({ commit }, form) {
      return CarsService.addCarTripEvent(form).then((response) => {
        commit('setMessage', { text: 'carEventAddSuccess', type: 'success' }, { root: true })
        return Promise.resolve(response)
      })
    },
    removeCarTripEvent({ commit }, tripId) {
      return CarsService.removeCarTripEvent(tripId).then((response) => {
        commit('setMessage', { text: 'carEventRemoveSuccess', type: 'success' }, { root: true })
        return Promise.resolve(response)
      })
    },
    updateCarTripEvent({ commit }, form) {
      return CarsService.updateCarTripEvent(form).then((response) => {
        commit('setMessage', { text: 'carEventUpdateSuccess', type: 'success' }, { root: true })
        return Promise.resolve(response)
      })
    },
    addCarTripEventInfo({ commit }, form) {
      return CarsService.addCarTripInfoEvent(form).then((response) => {
        commit('setMessage', { text: 'carEventInfoAddSuccess', type: 'success' }, { root: true })
        return Promise.resolve(response)
      })
    },
    UpdateCarTripEventInfo({ commit }, { tripInfoId, newGasolineEnd, newGasolineEndPerehod, tripEventId }) {
      // Предположим, что метод CarsService.updateCarTripInfoEvent отправляет запрос на сервер для обновления информации о поездке
      return CarsService.updateCarTripInfoEvent(tripInfoId, newGasolineEnd, newGasolineEndPerehod, tripEventId)
        .then((response) => {
          // Обработка успешного обновления информации о поездке
          return Promise.resolve(response)
        })
        .catch((error) => {
          // Обработка ошибки при обновлении информации о поездке
          console.error('Error updating car trip event info:', error)
          return Promise.reject(error)
        })
    },
    removeCarTripEventInfo({ commit }, tripInfoId) {
      return CarsService.removeCarTripEventInfoEvent(tripInfoId).then((response) => {
        return Promise.resolve(response)
      })
    },
    sendMessages({ commit }, requestData) {
      return CarsService.sendEmail(requestData).then((response) => {
        commit('setMessage', { text: 'carMessageSuccess', type: 'success' }, { root: true })
        return Promise.resolve(response)
      })
    },
    sendMessagesDriver({ commit }, requestData) {
      return CarsService.sendEmailDriver(requestData).then((response) => {
        commit('setMessage', { text: 'carMessageSuccess', type: 'success' }, { root: true })
        return Promise.resolve(response)
      })
    }
  },
  mutations: {
    carsLoading(state, status) {
      state.loading = status
    },
    carsLoadSuccess(state, data) {
      state.loading = false
      state.cars = data.cars
      state.techEventsTypes = data.tech_events_types
      state.tripEventsTypes = data.trip_events_types
    },
    carsLoadFailed(state) {
      state.loading = false
      state.cars = []
    }
  }
}
