<template>
  <nav v-if="user" class="nav__wrapper">
    <div class="nav">
      <div class="nav__group">
        <div class="nav__burger" v-if="userRoutes.length > 1">
          <font-awesome-icon @click="menuStatus = true" icon="bars" />
        </div>
        <div class="nav__list">
          <div v-for="route in userRoutes" :key="route.path" class="nav__item">
            <router-link class="nav__link" :to="route.path" exact> {{ route.name }} </router-link>
          </div>
        </div>
      </div>
      <div class="nav__group">
        <div class="nav__list">
          <div vclass="nav__item">
            <div v-if="this.remainingDays == 0" ref="fireworksContainer" class="fireworks-container"></div>
            <el-popover placement="bottom-end" effect="light" :width="400">
              <template #reference>
                <font-awesome-icon icon="cake-candles" />
              </template>
              <div>
                <div v-if="this.remainingDays == 0" class="birthday-cell today">С Днём Рождения!</div>
                <div v-else class="birthday-cell">
                  <span
                    v-if="this.remainingDays > 0"
                    class="today-marker"
                    :style="{
                      width: 100 - 100 * (this.remainingDays / this.daysInYear) + '%'
                    }"
                  ></span>
                  До твоего дня рождения осталось: {{ remainingDays }} {{ daysText }}
                </div>
              </div>
              <div>
                <div class="birthday-table">Празднуют день рождения в этом месяце:</div>
                <el-table :data="formattedBirthdayList" width="200" :show-header="false">
                  <el-table-column width="150" property="last_name" />
                  <el-table-column width="120" property="first_name" />
                  <el-table-column width="100" property="birthday">
                    <template #default="{ row }">
                      <div v-if="row.formattedDate === row.formattedCurrentDate">Сегодня!</div>
                      <div v-else>{{ row.formattedDate }}</div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <template> </template>
            </el-popover>
          </div>
          <div class="nav__item">
            <el-switch
              :model-value="isDarkTheme"
              inline-prompt
              active-icon="Moon"
              inactive-icon="Sunny"
              active-color="rgb(72 72 72)"
              @click="changeTheme"
              size="small"
            />
          </div>
          <div v-if="user" class="nav__item username">
            <el-dropdown>
              <span class="el-dropdown-link">
                <font-awesome-icon icon="user" /> {{ user.employee_name || user.username }}
                <el-icon><ArrowDown /></el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="handleChangePassword">Сменить пароль</el-dropdown-item>
                  <el-dropdown-item @click="$router.push({ name: 'utilities' })">Утилиты</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>

          <div v-if="user.is_staff" class="nav__item">
            <a class="nav__link" href="/admin/" target="_blank"> <font-awesome-icon icon="wrench" /></a>
          </div>
          <div class="nav__item">
            <a class="nav__link" href @click.prevent="logOut"> <font-awesome-icon icon="sign-out-alt" /></a>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <el-drawer :with-header="false" v-model="menuStatus" direction="ltr" size="50%">
    <div class="nav__burger-list">
      <div v-for="route in userRoutes" :key="route.path" class="nav__burger-item">
        <router-link :to="route.path" exact @click="menuStatus = false">
          {{ route.name }}
        </router-link>
      </div>
    </div>
  </el-drawer>
  <change-password-modal ref="passwordModal" />
</template>

<script>
import ChangePasswordModal from '../ChangePasswordModal.vue'
import { mapGetters, mapActions } from 'vuex'
import { Fireworks } from 'fireworks-js'

export default {
  components: { ChangePasswordModal },
  name: 'res-nav',
  data() {
    return {
      menuStatus: false,
      birthday: [],
      birthdayList: [],
      remainingDays: [],
      today: [],
      date: [],
      fireworksInstance: null
    }
  },
  created() {
    this.fetchBirthdayData().then(() => {
      this.findEmployeeWithBirthday()
      this.formattedEmployeeWithBirthday()
    })
  },
  methods: {
    ...mapActions(['changeTheme']),
    logOut() {
      this.$store.dispatch('auth/logout', this.user.refresh).then(() => this.$router.push('/login'))
    },
    startFireworks() {
      this.$nextTick(() => {
        const container = this.$refs.fireworksContainer

        if (container) {
          const options = {
            maxRockets: 3,
            rocketSpawnInterval: 150,
            numParticles: 100,
            explosionMinHeight: 0.2,
            explosionMaxHeight: 0.9,
            explosionChance: 0.06
          }

          this.fireworksInstance = new Fireworks(container, options)
          this.fireworksInstance.start()

          // Запуск таймера для плавного затухания
          setTimeout(() => {
            this.fadeOutFireworks()
          }, 10000)
        } else {
          console.error('Контейнер для фейерверка не найден.')
        }
      })
    },
    fadeOutFireworks() {
      const container = this.$refs.fireworksContainer

      if (container) {
        container.classList.add('fade-out')
        container.addEventListener('transitionend', () => {
          this.stopFireworks()
        })
      }
    },
    stopFireworks() {
      const fireworks = this.fireworksInstance

      if (fireworks) {
        fireworks.stop()
      } else {
        console.error('Фейерверки не были запущены.')
      }
    },
    handleChangePassword() {
      this.$refs.passwordModal.openModal()
    },
    async fetchBirthdayData() {
      try {
        const response = await this.$store.dispatch('employees/getEmployees')
        if (response) {
          this.birthday = response
          this.filterBirthdayList()
        }
      } catch (error) {
        console.error('Ошибка при получении данных о сотрудниках:', error)
      }
    },
    findEmployeeWithBirthday() {
      this.employeeWithBirthday = this.birthday.find((b) => b.user === this.user.id)
    },
    filterBirthdayList() {
      const currentDate = new Date()
      const currentMonth = currentDate.getMonth() + 1
      const employeesArray = Object.values(this.birthday)
      this.birthdayList = employeesArray.filter((employee) => {
        if (employee.birthday && employee.birthday !== 'null') {
          const userBirthdayMonth = new Date(employee.birthday).getMonth() + 1
          return userBirthdayMonth === currentMonth
        }
        return false
      })
    },
    formattedEmployeeWithBirthday() {
      if (this.employeeWithBirthday) {
        const today = new Date()
        const year = today.getFullYear()
        const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0
        const daysInYear = isLeapYear ? 366 : 365
        today.setHours(0, 0, 0, 0)
        const formattedEmployee = this.employeeWithBirthday
        const date = new Date(formattedEmployee.birthday)
        date.setHours(0, 0, 0, 0)
        date.setFullYear(today.getFullYear())

        if (date.getMonth() < today.getMonth()) {
          date.setFullYear(today.getFullYear() + 1)
        }

        if (date.getMonth() == today.getMonth()) {
          if (date.getDate() < today.getDate()) {
            date.setFullYear(today.getFullYear() + 1)
          }
        }

        const timeDifference = today.getTime() - date.getTime()
        const remainingDays = Math.abs(Math.floor(timeDifference / (1000 * 60 * 60 * 24)))

        this.remainingDays = remainingDays
        this.daysInYear = daysInYear
        this.date = date
        this.today = today.toDateString()
      } else {
        return null
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.startFireworks()
    }, 100)
  },
  computed: {
    ...mapGetters(['isDarkTheme']),
    user() {
      return this.$store.state.auth.user
    },
    daysText() {
      const days = this.remainingDays
      const lastDigit = days % 10
      const lastTwoDigits = days % 100

      if (lastDigit === 1 && lastTwoDigits !== 11) {
        return 'день'
      } else if (lastDigit >= 2 && lastDigit <= 4 && (lastTwoDigits < 10 || lastTwoDigits >= 20)) {
        return 'дня'
      } else {
        return 'дней'
      }
    },
    formattedBirthdayList() {
      const months = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря'
      ]

      const sortedList = this.birthdayList.slice().sort((a, b) => {
        const dateA = new Date(a.birthday)
        const dateB = new Date(b.birthday)
        return dateA.getDate() - dateB.getDate()
      })

      return sortedList.map((birthday) => {
        const date = new Date(birthday.birthday)
        const day = date.getDate()
        const month = months[date.getMonth()]
        const currentDate = new Date()
        const currentDay = currentDate.getDate()
        const currentMonth = months[currentDate.getMonth()]

        return {
          ...birthday,
          formattedDate: `${day} ${month}`,
          formattedCurrentDate: `${currentDay} ${currentMonth}`
        }
      })
    },
    userRoutes() {
      const allowedRoutes = this.$store.state.routes.filter((route) =>
        route.permission ? this.user.groups.includes(route.permission) : route
      )
      if (allowedRoutes.length > 1) return allowedRoutes.filter((route) => route.path != '/')
      return allowedRoutes
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-out {
  opacity: 0;
  transition: opacity 2s ease-out;
}
.fireworks-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  pointer-events: none;
}
.birthday-table {
  margin: 8px 0 2px 2px;
  padding-bottom: 6px;
  border-bottom: 1px solid var(--el-border-color-lighter);
}
.birthday-cell {
  position: relative;
  padding: 2px 5px;
  background-color: #f5f5f5;
  border: 1px solid #690e75;
  border-radius: 4px;
  margin: 4px 0;
  opacity: 0.9;
  color: #333;
}

.today-marker {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  background: linear-gradient(45deg, rgba(204, 0, 255, 0) 0%, rgba(204, 0, 255, 0.651) 100%);
}

.today {
  background-color: #cc00ff63;
  color: #fff;
  font-weight: bold;
}
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 15px 20px;
  color: var(--text-primary);

  &__burger {
    display: none;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      display: block;
    }

    &-list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 30px;
    }

    &-item {
      padding: 10px;
    }
  }

  &__group:first-child &__list {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  &__wrapper {
    position: sticky;
    top: 0;
    left: 0;
    background: var(--background-header);
    width: 100%;
  }

  &__list {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
  }

  &__item {
    user-select: none;
    &:not(:first-child) {
      margin-left: 15px;
    }

    &.username {
      cursor: pointer;
      font-weight: 500;
    }
  }

  &__link {
    outline: none;
    text-decoration: none;
    color: var(--text-primary);
    font-weight: 500;

    &:hover {
      filter: brightness(1.1);
    }

    &.router-link-active {
      position: relative;
      height: 100%;
      filter: brightness(1.05);

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 3px;
        bottom: -5px;
        left: 0;
        background: var(--border-base);
      }
    }
  }
}
</style>
