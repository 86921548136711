import { apiClient as axios } from './axiosAdapter'

const API_URL = 'invoices/'

class InvoicesService {
  updateInvoices(params) {
    return axios.get(API_URL, { params })
  }
  updateInvoicesInfo() {
    return axios.get(API_URL + 'info/')
  }
  createInvoice(form) {
    return axios.post(API_URL, form)
  }
  editInvoice(invoiceId, form) {
    console.log(form)
    return axios.patch(`${API_URL}${invoiceId}/`, form)
  }
  editInvoiceAgreed(invoiceId) {
    return axios.post(`${API_URL}${invoiceId}/change_agreed/`)
  }
  removeInvoice(invoiceId) {
    return axios.delete(`${API_URL}${invoiceId}/`)
  }
  addSpecification(form) {
    return axios.post(API_URL + 'specifications/add/', form)
  }
  bindSpecification(form) {
    return axios.post(API_URL + 'specifications/bind/', form)
  }
  unBindSpecification(form) {
    return axios.post(API_URL + 'specifications/unbind/', form)
  }
  changeSpecification(form) {
    return axios.put(API_URL + 'specifications/change/', form)
  }
  removeSpecification(form) {
    return axios.post(API_URL + 'specifications/delete/', form)
  }
}

export default new InvoicesService()
