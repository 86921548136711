import EmployeeService from '@/services/employees.service'

const initialState = {
  employees: []
}

export const employees = {
  namespaced: true,
  state: initialState,
  actions: {
    getEmployees({ commit }) {
      commit('EmployeesLoading', true)
      return EmployeeService.getEmployees().then(
        (employees) => {
          commit('getEmployeesSuccess', employees)
          return Promise.resolve(employees)
        },
        (error) => {
          commit('getEmployeesFailure')
          return Promise.reject(error)
        }
      )
    }
  },
  mutations: {
    EmployeesLoading(state, status) {
      state.loading = status
    },
    getEmployeesSuccess(state, data) {
      state.loading = false
      state.employees = data.employees
    },
    getEmployeesFailure(state) {
      state.loading = false
      state.employees = []
    }
  }
}
