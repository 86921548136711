import MonitoringService from "@/services/monitoring.service";

const initialState = {
  loading: false,
  units: [],
  statisticLoading: false,
  unitsStatistic: [],
  notifictionsLoading: false,
  notifications: [],
  notificationsHistory: [],
  notificationsRecipientsAndUnits: { units: [], recipients: [] }
};

export const monitoring = {
  namespaced: true,
  state: initialState,
  actions: {
    updateUnits({ commit }, date) {
      commit("unitsLoading", true);
      return MonitoringService.updateUnitsStatus(date).then(
        units => {
          commit("unitsSuccess", units);
          return Promise.resolve(units);
        },
        error => {
          commit("unitsFailure");
          return Promise.reject(error);
        }
      );
    },
    updateUnitsStatistic({ commit }, params) {
      commit("unitsStatisticLoading", true);
      return MonitoringService.updateUnitsStatistic(params).then(
        units => {
          commit("unitsStatisticSuccess", units);
          return Promise.resolve(units);
        },
        error => {
          commit("unitsStatisticFailure");
          return Promise.reject(error);
        }
      );
    },
    fetchNotifications({ commit }) {
      commit("notificationsLoading", true);
      return MonitoringService.fetchNotifications().then(
        notifications => {
          commit("notificationsLoadingSuccess", notifications);
          return Promise.resolve(notifications);
        },
        error => {
          commit("notificationsLoadingFailure");
          return Promise.reject(error);
        }
      );
    },
    fetchNotificationsRecipientsAndUnits({ commit }) {
      commit("notificationsLoading", true);
      return MonitoringService.fetchNotificationsRecipientsAndUnits().then(
        recepientsAndUnits => {
          commit("fetchNotificaitonsRecipientsSuccess", recepientsAndUnits);
          return Promise.resolve(recepientsAndUnits);
        },
        error => {
          commit("fetchNotificaitonsRecipientsFailure");
          return Promise.reject(error);
        }
      );
    },
    createNotification({ commit }, form) {
      commit("notificationsLoading", true);
      return MonitoringService.createNotification(form)
        .then(notification => {
          commit("setMessage", { text: "createMonitoringNotificationSuccess", type: "success" }, { root: true });
          return Promise.resolve(notification);
        })
        .finally(() => commit("notificationsLoading", false));
    },
    fetchNotification({ commit }, notificationId) {
      commit("notificationsLoading", true);
      return MonitoringService.fetchNotification(notificationId)
        .then(notification => {
          return Promise.resolve(notification);
        })
        .finally(() => commit("notificationsLoading", false));
    },
    updateNotification({ commit, dispatch }, form) {
      commit("notificationsLoading", true);
      return MonitoringService.updateNotification(form).then(
        data => {
          dispatch("fetchNotifications");
          commit("setMessage", { text: "updateMonitoringNotificationSuccess", type: "success" }, { root: true });
          return Promise.resolve(data);
        },
        error => {
          commit("notificationsLoading", false);
          return Promise.reject(error);
        }
      );
    },
    deleteNotification({ commit, dispatch }, notificationId) {
      commit("notificationsLoading", true);
      return MonitoringService.deleteNotification(notificationId)
        .then(data => {
          dispatch("fetchNotifications");
          commit("setMessage", { text: "deleteMonitoringNotificationSuccess", type: "success" }, { root: true });
          return Promise.resolve(data);
        })
        .finally(() => commit("notificationsLoading", false));
    },
    createRecipient({ commit }, form) {
      commit("notificationsLoading", true);
      return MonitoringService.createRecipient(form)
        .then(recipient => {
          commit(
            "setMessage",
            { text: "createMonitoringNotificationRecipientSuccess", type: "success" },
            { root: true }
          );
          return Promise.resolve(recipient);
        })
        .finally(() => commit("notificationsLoading", false));
    },
    updateRecipient({ commit }, form) {
      commit("notificationsLoading", true);
      return MonitoringService.updateRecipient(form).then(
        data => {
          commit(
            "setMessage",
            { text: "updateMonitoringNotificationRecipientSuccess", type: "success" },
            { root: true }
          );
          return Promise.resolve(data);
        },
        error => {
          commit("notificationsLoading", false);
          return Promise.reject(error);
        }
      );
    },
    deleteRecipient({ commit, dispatch }, recipientId) {
      commit("notificationsLoading", true);
      return MonitoringService.deleteRecipient(recipientId)
        .then(data => {
          dispatch("fetchNotificationsRecipientsAndUnits");
          commit(
            "setMessage",
            { text: "deleteMonitoringNotificationRecipientSuccess", type: "success" },
            { root: true }
          );
          return Promise.resolve(data);
        })
        .finally(() => commit("notificationsLoading", false));
    },
    fetchNotificationsHistory({ commit }, params) {
      commit("notificationsLoading", true);
      return MonitoringService.fetchNotificationsHistory(params).then(
        notifications => {
          commit("fetchNotificaitonsHistorySuccess", notifications);
          return Promise.resolve(notifications);
        },
        error => {
          commit("fetchNotificaitonsHistoryFailure");
          return Promise.reject(error);
        }
      );
    }
  },
  mutations: {
    unitsLoading(state, status) {
      state.loading = status;
    },
    unitsSuccess(state, units) {
      state.loading = false;
      state.units = units;
    },
    unitsFailure(state) {
      state.loading = false;
      state.units = [];
    },
    unitsStatisticLoading(state, status) {
      state.statisticLoading = status;
    },
    unitsStatisticSuccess(state, units) {
      state.statisticLoading = false;
      state.unitsStatistic = units;
    },
    unitsStatisticFailure(state) {
      state.statisticLoading = false;
      state.unitsStatistic = [];
    },
    notificationsLoading(state, status) {
      state.notificationsLoading = status;
    },
    notificationsLoadingSuccess(state, notifications) {
      state.notifications = notifications;
      state.notificationsLoading = false;
    },
    notificationsLoadingFailure(state) {
      state.notifications = [];
      state.notificationsLoading = false;
    },
    fetchNotificaitonsRecipientsSuccess(state, recipientsAndUnits) {
      state.notificationsRecipientsAndUnits = recipientsAndUnits;
      state.notificationsLoading = false;
    },
    fetchNotificaitonsRecipientsFailure(state) {
      state.notificationsRecipientsAndUnits = initialState.notificationsRecipientsAndUnits;
      state.notificationsLoading = false;
    },
    fetchNotificaitonsHistorySuccess(state, notifications) {
      state.notificationsHistory = notifications;
      state.notificationsLoading = false;
    },
    fetchNotificaitonsHistoryFailure(state) {
      state.notificationsHistory = initialState.notificationsHistory;
      state.notificationsLoading = false;
    }
  }
};
