import ContractsService from "@/services/contracts.service";
import * as types from "./mutations_types";

export default {
  fetchContracts({ commit }, params) {
    commit(types.FETCH_CONTRACTS);
    return ContractsService.fetchContracts(params).then(
      data => {
        commit(types.FETCH_CONTRACTS_SUCCESS, data);
        return Promise.resolve(data);
      },
      error => {
        commit(types.FETCH_CONTRACTS_ERROR);
        return Promise.reject(error);
      }
    );
  },
  createContract({ commit }, form) {
    return ContractsService.createContract(form).then(() => {
      commit("setMessage", { text: "contractCreateSuccess", type: "success" }, { root: true });
      return Promise.resolve();
    });
  },
  editContract({ commit }, payload) {
    return ContractsService.editContract(payload.contractId, payload.form).then(() => {
      commit("setMessage", { text: "contractEditSuccess", type: "success" }, { root: true });
      return Promise.resolve();
    });
  },
  createStep({ commit }, form) {
    return ContractsService.createStep(form).then(() => {
      commit("setMessage", { text: "contractStepCreateSuccess", type: "success" }, { root: true });
      return Promise.resolve();
    });
  },
  editStep({ commit }, payload) {
    return ContractsService.editStep(payload.stepId, payload.form).then(() => {
      commit("setMessage", { text: "contractStepEditSuccess", type: "success" }, { root: true });
      return Promise.resolve();
    });
  },
  deleteStep({ commit }, stepId) {
    return ContractsService.deleteStep(stepId).then(() => {
      commit("setMessage", { text: "contractStepDeleteSuccess", type: "success" }, { root: true });
      return Promise.resolve();
    });
  },
  deleteContractAgreement({ commit }, agreementId) {
    return ContractsService.deleteContractAgreement(agreementId).then(() => {
      commit("setMessage", { text: "contractAgreementDeleteSuccess", type: "success" }, { root: true });
      return Promise.resolve();
    });
  },
  createContractAgreement({ commit }, form) {
    return ContractsService.createContractAgreement(form).then(() => {
      commit("setMessage", { text: "contractAgreementCreateSuccess", type: "success" }, { root: true });
      return Promise.resolve();
    });
  },
  fetchContacts({ commit  }, params) {
    return ContractsService.fetchContacts(params).then(data => {
        commit("fetchContactsSuccess", data);
        return Promise.resolve(data);
    });
  },
  createContact({ commit  }, form) {
    return ContractsService.createContact(form).then(() => {
        commit("setMessage", { text: "createContractsContactSuccess", type: "success" }, { root: true });
        return Promise.resolve();
      });
  },
  deleteContact({ commit }, contactId) {
    commit("changeLoading", true);
    return ContractsService.deleteContact(contactId).then(
      data => {
        commit("setMessage", { text: "deleteContractsContactSuccess", type: "success" }, { root: true });
        commit("changeLoading", false);
        return Promise.resolve(data);
      }
    );
  },
  editContact({ commit }, payload){
    return ContractsService.editContact(payload.contactId, payload.form).then(() => {
      commit("setMessage", { text: "contactEditSuccess", type: "success" }, { root: true });
      return Promise.resolve();
    });
  }
};
