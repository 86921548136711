import ReszipService from "@/services/reszip.service";

const initialState = {
  loading: false,
  electrometers: [],
  anyequipments:[],
  equipments:[],
  history:[],
};

export const reszip = {
  namespaced: true,
  state: initialState,
  actions: {
    fetchElectroMeters({ commit }) {
      commit("changeLoading", true);
      return ReszipService.getElectroMeters().then(
        electrometers => {
          commit("fetchElectroMetersSuccess", electrometers);
          return Promise.resolve(electrometers);
        },
        error => {
          commit("fetchElectroMetersFailure");
          return Promise.reject(error);
        }
      )
     },
    createElectrometer({ commit, dispatch }, form) {
      commit("changeLoading", true);
      return ReszipService.createElectrometer(form).then(
        data => {
          commit("changeLoading", false);
          commit("setMessage", { text: "createReszipElectrometerSuccess", type: "success" }, { root: true });
          dispatch("fetchElectroMeters");
          return Promise.resolve(data);
        },
        error => {
          commit("changeLoading", false);
          return Promise.reject(error);
        }
      );
    },
    editElectrometer({ commit, dispatch }, form) {
      commit("changeLoading", true);
      return ReszipService.editElectrometer(form).then(
        data => {
          commit("changeLoading", false);
          commit("setMessage", { text: "editReszipElectrometerSuccess", type: "success" }, { root: true });
          dispatch("fetchElectroMeters");
          return Promise.resolve(data);
        },
        error => {
          commit("changeLoading", false);
          return Promise.reject(error);
        }
      );
    },
    deleteElectrometer({ commit, dispatch }, electrometerId) {
      commit("changeLoading", true);
      return ReszipService.deleteElectrometer(electrometerId).then(
        data => {
          commit("changeLoading", false);
          commit("setMessage", { text: "deleteReszipElectrometerSuccess", type: "success" }, { root: true });
          dispatch("fetchElectroMeters");
          return Promise.resolve(data);
        },
        error => {
          commit("changeLoading", false);
          return Promise.reject(error);
        }
      );
    },
    fetchHistory({ commit }) {
      commit("changeLoading", true);
      return ReszipService.getHistory().then(
        history => {
          commit("fetchHistorySuccess", history);
          return Promise.resolve(history);
        },
        error => {
          commit("fetchHistoryFailure");
          return Promise.reject(error);
        }
      )
     },
    createHistory({ commit, dispatch }, {id,form}) {
      commit("changeLoading", true);
      return ReszipService.createHistory(form).then(
        data => {
          commit("changeLoading", false);
          commit("setMessage", { text: "createReszipHistorySuccess", type: "success" }, { root: true });
          dispatch("fetchEquipments", id);
          dispatch("fetchHistory");
          return Promise.resolve(data);
        },
        error => {
          commit("changeLoading", false);
          return Promise.reject(error);
        }
      );
    },
    editHistory({ commit, dispatch }, form) {
      commit("changeLoading", true);
      return ReszipService.editHistory(form).then(
        data => {
          commit("changeLoading", false);
          commit("setMessage", { text: "editReszipHistorySuccess", type: "success" }, { root: true });
          dispatch("fetchHistory");
          return Promise.resolve(data);
        },
        error => {
          commit("changeLoading", false);
          return Promise.reject(error);
        }
      );
    },
    deleteHistory({ commit, dispatch }, historyId) {
      commit("changeLoading", true);
      return ReszipService.deleteHistory(historyId).then(
        data => {
          commit("changeLoading", false);
          commit("setMessage", { text: "deleteReszipHistorySuccess", type: "success" }, { root: true });
          dispatch("fetchHistory");
          return Promise.resolve(data);
        },
        error => {
          commit("changeLoading", false);
          return Promise.reject(error);
        }
      );
    },
    fetchAnyequipments({ commit }) {
      commit("changeLoading", true);
      return ReszipService.getAnyequipments().then(
        anyequipments => {
          commit("fetchAnyequipmentsSuccess", anyequipments);
          return Promise.resolve(anyequipments);
        },
        error => {
          commit("fetchAnyequipmentsFailure");
          return Promise.reject(error);
        }
      )
     },
    createAnyequipment({ commit, dispatch }, form) {
      commit("changeLoading", true);
      return ReszipService.createAnyequipment(form).then(
        data => {
          commit("changeLoading", false);
          commit("setMessage", { text: "createReszipAnyequipmentSuccess", type: "success" }, { root: true });
          dispatch("fetchAnyequipments");
          return Promise.resolve(data);
        },
        error => {
          commit("changeLoading", false);
          return Promise.reject(error);
        }
      );
    },
    editAnyequipment({ commit, dispatch }, form) {
      commit("changeLoading", true);
      return ReszipService.editAnyequipment(form).then(
        data => {
          commit("changeLoading", false);
          commit("setMessage", { text: "editReszipAnyequipmentSuccess", type: "success" }, { root: true });
          dispatch("fetchAnyequipments");
          return Promise.resolve(data);
        },
        error => {
          commit("changeLoading", false);
          return Promise.reject(error);
        }
      );
    },
    deleteAnyequipment({ commit, dispatch }, anyequipmentId) {
      commit("changeLoading", true);
      return ReszipService.deleteAnyequipment(anyequipmentId).then(
        data => {
          commit("changeLoading", false);
          commit("setMessage", { text: "deleteReszipAnyequipmentSuccess", type: "success" }, { root: true });
          dispatch("fetchAnyequipments");
          return Promise.resolve(data);
        },
        error => {
          commit("changeLoading", false);
          return Promise.reject(error);
        }
      );
    },
    fetchEquipments({ commit },id) {
      commit("changeLoading", true);
      return ReszipService.getEquipments(id).then(
        equipments => {
          commit("changeLoading", false);
          commit("fetchEquipmentsSuccess", equipments);
          return Promise.resolve(equipments);  
        },
        error => {
          commit("changeLoading", false);
          commit("fetchEquipmentsFailure");
          return Promise.reject(error);
        }
        
      )
     },
    createEquipment({ commit, dispatch }, {id, form}) {
      commit("changeLoading", true);
      return ReszipService.createEquipment(form).then(
        data => {
          commit("changeLoading", false);
          commit("setMessage", { text: "createReszipEquipmentSuccess", type: "success" }, { root: true });
          dispatch("fetchEquipments", id);
          return Promise.resolve(data);   
        },
        error => {
          commit("changeLoading", false);
          return Promise.reject(error);
        }
      );
    },
    editEquipment({ commit, dispatch }, {id, id_type, form}) {
      commit("changeLoading", true);
      return ReszipService.editEquipment(id, form).then(
        data => {
          commit("changeLoading", false);
          commit("setMessage", { text: "editReszipEquipmentSuccess", type: "success" }, { root: true });
          dispatch("fetchEquipments",id_type);
          return Promise.resolve(data);
        },
        error => {
          commit("changeLoading", false);
          return Promise.reject(error);
        }
      );
    },
    editEquipmentQuantity({ commit, dispatch }, {id, quantity,name,type,form}) {
      commit("changeLoading", true);
      return ReszipService.editEquipment(id, {quantity,name,type}).then(
        data => {
          dispatch("createHistory",{id: type, form});
          return Promise.resolve(data);
        },
        error => {
          commit("changeLoading", false);
          return Promise.reject(error);
        }
      );
    },
    deleteEquipment({ commit, dispatch }, {id_type,equipmentId}) {
      commit("changeLoading", true);
      return ReszipService.deleteEquipment(equipmentId).then(
        data => {
          commit("changeLoading", false);
          commit("setMessage", { text: "deleteReszipEquipmentSuccess", type: "success" }, { root: true });
          dispatch("fetchEquipments",id_type);
          return Promise.resolve(data);
        },
        error => {
          commit("changeLoading", false);
          return Promise.reject(error);
        }
      );
    },
},
  mutations: {
    changeLoading(state, status) {
      state.loading = status;
    },
    fetchElectroMetersSuccess(state, electrometers) {
      state.loading = false;
      state.electrometers = electrometers;
    },
    fetchElectroMetersFailure(state) {
      state.loading = false;
      state.electrometers = [];
    },
    fetchAnyequipmentsSuccess(state, anyequipments) {
      state.loading = false;
      state.anyequipments = anyequipments;
    },
    fetchAnyequipmentsFailure(state) {
      state.loading = false;
      state.anyequipments = [];
    },
    fetchEquipmentsSuccess(state, equipments) {
      state.loading = false;
      state.equipments = equipments;
    },
    fetchEquipmentsFailure(state) {
      state.loading = false;
      state.equipments = [];
    },
    fetchHistorySuccess(state, history) {
      state.loading = false;
      state.history = history;
    },
    fetchHistoryFailure(state) {
      state.loading = false;
      state.history = [];
    },
  }
};
