<template>
  <div class="card__wrapper">
    <div class="card">
      <div v-if="status !== null" :class="[status ? 'red' : 'green']" class="card__status" />
      <div class="card__title">
        <slot name="title">TITLE</slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'res-card',
  props: { status: { type: Boolean, required: false, default: null } }
}
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  align-items: center;
  background: var(--background-header);
  padding: 10px;
  border-radius: 4px;
  width: 100%;

  &__wrapper {
    display: flex;
    padding: 5px;
    user-select: none;
    cursor: pointer;
    transition: all 0.3s ease;
    overflow: hidden;

    &:hover {
      filter: brightness(1.1);
    }

    &:active {
      filter: brightness(1);
    }
  }

  &__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__status {
    min-width: 10px;
    min-height: 10px;
    border-radius: 50%;
    margin-right: 7px;
  }
}
</style>
