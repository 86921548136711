import AuthService from "@/services/auth.service";
import * as types from "./mutations_types";

export default {
  login({ commit }, user) {
    return AuthService.login(user).then(
      user => {
        commit(types.LOGIN_SUCCESS, user);
        commit("setMessage", { text: "loginSuccess", type: "success" }, { root: true });
        return Promise.resolve(user);
      },
      error => {
        commit("setMessage", { text: error.response?.data?.detail, type: "error" }, { root: true });
        return Promise.reject(error);
      }
    );
  },
  logout({ commit }, refresh_token) {
    return AuthService.logout(refresh_token)
      .then(() => commit("setMessage", { text: "logout", type: "success" }, { root: true }))
      .finally(() => {
        commit(types.LOGOUT);
        return Promise.resolve();
      });
  },
  changePassword({ commit }, form) {
    return AuthService.changePassword(form).then(data => {
      commit("setMessage", { text: "changePasswordSuccess", type: "success" }, { root: true });
      return Promise.resolve(data);
    });
  },
  updateUserInfo({ commit, state }) {
    return AuthService.updateUserInfo().then(
      userData => {
        commit(types.FETCH_USER_SUCCESS, userData);
        return Promise.resolve(userData);
      },
      error => {
        if (!state.user) commit(types.LOGOUT);
        return Promise.reject(error);
      }
    );
  },
  updateRefreshToken({ commit }, form) {
    const callingFunction = AuthService.updateRefreshToken(form).then(
      tokens => {
        commit(types.UPDATE_REFRESH_TOKEN_SUCCESS, tokens);
        return Promise.resolve(tokens);
      },
      error => {
        commit(types.LOGOUT);
        return Promise.reject(error);
      }
    );
    commit(types.UPDATE_REFRESH_TOKEN, callingFunction);
    return callingFunction;
  }
};
