import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import components from "@/components/UI";
import FontAwesomeIcon from "@/plugins/font-awesome";

import * as icons from "@element-plus/icons-vue";
import ElementPlus from "element-plus";

import { vue3Debounce } from "vue-debounce";

import "element-plus/dist/index.css";
import "element-plus/theme-chalk/dark/css-vars.css";
import ru from "element-plus/es/locale/lang/ru";
import "./scss/element-variables.scss";

import "dayjs/locale/ru";

import "./registerServiceWorker";

const app = createApp(App);

app.use(router);
app.use(store);

app.component("font-awesome-icon", FontAwesomeIcon);

app.directive("debounce", vue3Debounce({ lock: true }));

app.use(ElementPlus, { locale: ru });
Object.entries(icons).forEach(([name, component]) => app.component(name, component));

components.forEach(component => app.component(component.name, component));

app.mount("#app");
