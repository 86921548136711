import AtsService from '@/services/ats.service'

const initialState = {
  isLoading: false,
  tableExams: [],
  totalpages: 0,
  trueResultsCount: 0,
  falseResultsCount: 0,
  totalRows: 0
}

export const ats = {
  namespaced: true,
  state: initialState,
  actions: {
    fetchExams({ commit }, params) {
      commit('changeLoading', true)
      return AtsService.fetchExams(params).then(
        (exam) => {
          commit('fetchAtsSuccess', exam)
          return Promise.resolve(exam)
        },
        (error) => {
          commit('fetchAtsFailure')
          return Promise.reject(error)
        }
      )
    },
    deleteExam({ commit, dispatch }, ExamId) {
      commit('changeLoading', true)
      return AtsService.deleteExam(ExamId).then(
        (exam) => {
          commit('setMessage', { text: 'объект удалён', type: 'success' }, { root: true })
          dispatch('fetchResultCount')
          return Promise.resolve(exam)
        },
        (error) => {
          commit('changeLoading', false)
          return Promise.reject(error)
        }
      )
    },
    addExam({ commit, dispatch }, formData) {
      commit('changeLoading', false)
      return AtsService.addExam(formData).then(
        (exam) => {
          dispatch('fetchExams', { page: 1, page_size: 10 })
          dispatch('fetchResultCount')
          commit('setMessage', { text: 'объект добавлен', type: 'success' }, { root: true })
          return Promise.resolve(exam)
        },
        (error) => {
          commit('changeLoading', false)
          return Promise.reject(error)
        }
      )
    },
    editExam({ commit, dispatch }, formData) {
      commit('changeLoading', false)
      return AtsService.editExam(formData).then(
        (exam) => {
          dispatch('fetchExams', { page: 1, page_size: 10 })
          dispatch('fetchResultCount')
          commit('setMessage', { text: 'объект изменён', type: 'success' }, { root: true })
          return Promise.resolve(exam)
        },
        (error) => {
          commit('changeLoading', false)
          return Promise.reject(error)
        }
      )
    },
    editExamResult({ commit, dispatch }, formData) {
      commit('changeLoading', false)
      return AtsService.editExamResult(formData).then(
        (exam) => {
          dispatch('fetchExams', { page: 1, page_size: 10 })
          dispatch('fetchResultCount')
          commit('setMessage', { text: 'результат изменён', type: 'success' }, { root: true })
          return Promise.resolve(exam)
        },
        (error) => {
          commit('changeLoading', false)
          return Promise.reject(error)
        }
      )
    },
    fetchResultCount({ commit }) {
      commit('changeLoading', false)
      return AtsService.fetchResultCount().then(
        (result) => {
          commit('fetchResultCountSuccess', result)
          return Promise.resolve(result)
        },
        (error) => {
          commit('fetchResultCountFailure')
          return Promise.reject(error)
        }
      )
    }
  },
  mutations: {
    changeLoading(state, status) {
      state.isLoading = status
    },
    fetchAtsSuccess(state, exam) {
      state.isLoading = false
      state.tableExams = exam.results
      state.totalpages = exam.total
    },
    fetchAtsFailure(state) {
      state.isLoading = false
      state.tableExams = []
      state.totalpages = 0
    },
    fetchResultCountSuccess(state, result) {
      state.isLoading = false
      state.trueResultsCount = result.true_results_count
      state.falseResultsCount = result.false_results_count
      state.totalRows = result.total_results
    },
    fetchResultCountFailure(state) {
      state.isLoading = false
      state.trueResultsCount = 0
      state.falseResultsCount = 0
      state.totalRows = 0
    }
  },
  getters: {
    isLoading(state) {
      return state.isLoading
    },
    tableExams(state) {
      return state.tableExams
    }
  }
}
