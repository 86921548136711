<template>
  <el-upload
    ref="upload"
    action="#"
    :accept="accept"
    :http-request="actionMethod"
    :limit="1"
    :on-exceed="handleExceed"
    :auto-upload="false"
    style="height: 70px; overflow: hidden"
  >
    <template #trigger>
      <res-button>Выбрать файл</res-button>
    </template>
    <res-button type="success" style="margin-left: 5px" @click="handleUpload" icon="Upload" plain>
      Отправить
    </res-button>
  </el-upload>
</template>
<script>
export default {
  name: "res-upload-v2",
  props: { actionMethod: { type: Function, required: true }, accept: { type: String, required: false, default: null } },
  methods: {
    handleUpload() {
      this.$refs.upload.submit();
    },
    handleExceed(files) {
      this.$refs.upload.clearFiles();
      const file = files[0];
      this.$refs.upload.handleStart(file);
    }
  }
};
</script>
