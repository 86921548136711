import { apiClient as axios } from './axiosAdapter'

const API_URL = 'employees/'

class EmployeeService {
  getEmployees() {
    return axios.get(API_URL)
  }
}

export default new EmployeeService()
