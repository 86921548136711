import { apiClient as axios } from "./axiosAdapter";

const API_URL = "reszip/";

class ReszipService {
  getElectroMeters() {
    return axios.get(API_URL + "electrometer/");
  }
  createElectrometer(form) {
    return axios.post(API_URL + "electrometer/", form);
  }
  editElectrometer(form) {
    return axios.put(API_URL + `electrometer/${form.id}/`, form);
  }
  deleteElectrometer(electrometerId) {
    return axios.delete(API_URL + `electrometer/${electrometerId}/`);
  }
  getAnyequipments() {
    return axios.get(API_URL + "anyequipment/");
  }
  createAnyequipment(form) {
    return axios.post(API_URL + "anyequipment/", form);
  }
  editAnyequipment(form) {
    return axios.put(API_URL + `anyequipment/${form.id}/`, form);
  }
  deleteAnyequipment(anyequipmentId) {
    return axios.delete(API_URL + `anyequipment/${anyequipmentId}/`);
  }
  getEquipments(id) {
    return axios.get(API_URL + `anyequipment_info/${id}`);
  }
  createEquipment(form) {
    return axios.post(API_URL + "equipment/", form);
  }
  editEquipment(id, form) {
    return axios.put(API_URL + `equipment/${id}/`, form);
  }
  deleteEquipment(equipmentId) {
    return axios.delete(API_URL + `equipment/${equipmentId}/`);
  }
  getHistory() {
    return axios.get(API_URL + "history/");
  }
  createHistory(form) {
    return axios.post(API_URL + "history/", form);
  }
  editHistory(form) {
    return axios.put(API_URL + `history/${form.id}/`, form);
  }
  deleteHistory(historyId) {
    return axios.delete(API_URL + `history/${historyId}/`);
  }
}

export default new ReszipService();