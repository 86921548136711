<template>
  <el-scrollbar class="res-list" :style="[height && `height: ${height}px; padding-right: 10px`]">
    <div class="res-list__item" v-for="(item, idx) in items" :key="idx" :style="style">
      <div class="res-list__item-label">
        <slot name="label" :row="item">{{ item[prop] }}</slot>
      </div>
      <div v-if="$slots.extra" class="res-list__item-extra"><slot name="extra" :row="item" /></div>
    </div>
  </el-scrollbar>
</template>
<script>
export default {
  name: "res-list",
  props: {
    items: { type: Array, required: true },
    prop: { type: String, required: true },
    height: { type: Number, required: false },
    style: { type: Object, required: false }
  }
};
</script>
<style lang="scss" scoped>
.res-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;

  &__item:not(:last-child) {
    margin-bottom: 7px;
  }

  &__item {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 5px 6px;
    background: rgba(116, 116, 129, 0.2);
    border-radius: 3px;
    white-space: nowrap;
    font-size: 15px;
    color: var(--text-primary);
    transition: all 0.3s ease;

    &:hover {
      filter: brightness(1.1);
    }

    &-label {
      display: flex;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
