<template>
  <el-dialog v-model="dialogVisibility" width="400px">
    <template #header>Смена пароля</template>
    <el-form ref="form" :rules="rules" :model="form" label-position="top" :disabled="isLoading">
      <el-form-item prop="old_password" label="Старый пароль">
        <el-input v-model="form.old_password" show-password />
      </el-form-item>
      <el-form-item prop="password" label="Новый пароль">
        <el-input v-model="form.password" show-password maxlength="16" />
      </el-form-item>
      <el-form-item prop="password2" label="Повтор нового пароля">
        <el-input v-model="form.password2" show-password maxlength="16" />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row :class="{ disabled: isLoading }">
        <res-button type="success" plain @click.prevent="handleSubmit">Сменить пароль</res-button>
        <res-button @click.prevent="dialogVisibility = false">Отмена</res-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisibility: false,
      isLoading: false,
      form: {
        old_password: null,
        password: null,
        password2: null
      },
      rules: {
        old_password: { required: true, message: 'Введите старый пароль', trigger: 'change' },
        password: {
          required: true,
          min: 8,
          max: 16,
          message: 'Новый пароль должен быть от 8 до 16 символов',
          trigger: 'change'
        },
        password2: {
          required: true,
          min: 8,
          max: 16,
          message: 'Новый пароль должен быть от 8 до 16 символов',
          trigger: 'change'
        }
      }
    }
  },
  methods: {
    openModal() {
      this.dialogVisibility = true
    },
    resetForm() {
      this.$refs.form.resetFields()
    },
    handleSubmit() {
      this.isLoading = true
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$store
            .dispatch('auth/changePassword', this.form)
            .then(() => {
              this.dialogVisibility = false
              this.resetForm()
            })
            .finally(() => (this.isLoading = false))
        } else {
          this.isLoading = false
        }
      })
    }
  },
  watch: {
    dialogVisibility() {
      if (!this.dialogVisibility) this.resetForm()
    }
  }
}
</script>
