const parseResponseFilename = response => {
  const disposition = response.headers["content-disposition"];
  const filename = disposition?.match(/filename="(?<filename>.+)"/)?.groups?.filename;
  return filename ? decodeURI(filename) : "unknown.file";
};

export const dowloadFileFromResponse = response => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", parseResponseFilename(response));
  document.body.appendChild(link);
  link.click();
};

export const watchFileFromResponse = response => {
  const fileURL = window.URL.createObjectURL(response.data);
  window.open(fileURL);
};

const getFormattedFormMessage = error => {
  if (error.response?.status === 400 && typeof error.response?.data === "object") {
    const responseData = error.response.data;
    return Object.keys(responseData)
      .map(key => `Поле ${key}: ${responseData[key].join("")}`)
      .join("\n");
  }
  return "";
};

export const getErrorMessage = error => {
  return error.response?.data?.detail || getFormattedFormMessage(error) || `Ошибка сервера (${error.response.status})`;
};
