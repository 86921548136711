<template>
  <div class="tree__item">
    <div
      class="tree__item-info"
      @click="openStatus = !openStatus"
      :class="[hasNestedSlot && (openStatus ? 'close' : 'open')]"
    >
      <div class="tree__item-group">
        <span v-if="status != null" class="tree__item-status" :class="[status ? 'red' : 'green']" />
        <div class="tree__item-title">
          <slot name="title" />
        </div>
      </div>
      <div class="tree__item-right">
        <slot name="right" />
        <el-icon v-if="hasNestedSlot" class="tree__item-right__icon">
          <ArrowLeft />
        </el-icon>
      </div>
    </div>

    <div v-if="hasNestedSlot" class="tree__item-nested">
      <slot name="nested"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "res-tree-item",
  props: {
    status: { type: Boolean, required: false, default: null }
  },
  data() {
    return {
      openStatus: false
    };
  },
  computed: {
    hasNestedSlot() {
      return !!this.$slots.nested;
    }
  }
};
</script>

<style lang="scss" scoped>
.tree {
  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__item-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(#747481, 0.1);
    padding: 10px;
    border-radius: 4px;
    font-size: 15px;
    transition: all 0.3s ease;
    overflow: hidden;

    &.close > .tree__item-right > .tree__item-right__icon {
      transform: rotate(-90deg);
    }
    &.close {
      ~ .tree__item-nested {
        display: block;
      }
    }
  }

  &__item-group {
    display: flex;
    align-items: center;
  }

  &__item-status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 7px;
  }

  &__item-right {
    display: flex;
    color: var(--el-text-color-primary);

    &__icon {
      color: var(--el-text-color-primary);
      cursor: pointer;
      transition: all 0.5s ease;
      margin-left: 10px;
    }
  }

  &__item-nested {
    display: none;
    margin: 10px 0 0 10px;
  }
}
</style>
