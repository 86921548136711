<template>
  <el-button>
    <slot />
  </el-button>
</template>

<script>
export default {
  name: "res-button"
};
</script>
