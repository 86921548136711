import * as types from "./mutations_types";

export default {
  [types.LOGIN_SUCCESS](state, user) {
    state.status.loggedIn = true;
    state.user = user;
  },
  [types.LOGIN_ERROR](state) {
    state.status.loggedIn = false;
    state.user = null;
  },
  [types.LOGOUT](state) {
    state.status.loggedIn = false;
    state.user = null;
    localStorage.removeItem("user");
  },
  [types.FETCH_USER_SUCCESS](state, user) {
    state.status.loggedIn = true;
    state.user = { ...state.user, ...user };
    localStorage.setItem("user", JSON.stringify(state.user));
  },
  [types.UPDATE_REFRESH_TOKEN](state, fn) {
    state.status.callingRefresh = fn;
    state.status.callingRefreshCount++;
  },
  [types.UPDATE_REFRESH_TOKEN_SUCCESS](state, tokens) {
    state.status.loggedIn = true;
    state.status.callingRefresh = null;
    state.status.callingRefreshCount = 0;
    state.user = { ...state.user, ...tokens };
    localStorage.setItem("user", JSON.stringify(state.user));
  }
};
