import { apiClient as axios } from './axiosAdapter'

const API_URL = 'lectures/'

class LecturesService {
  fetchLecturesThemes(params) {
    return axios.get(`${API_URL}themes/`, { params })
  }
  downloadLecturesFile(form) {
    return axios.post(`${API_URL}themes/`, form)
  }
  updateLecturesLesson(id, form) {
    return axios.patch(`${API_URL}themes/${id}/`, form)
  }
}

export default new LecturesService()
