<template>
  <div class="checkbox__container">
    <input
      class="checkbox"
      type="checkbox"
      id="res-checkbox"
      :value="modelValue"
      @input="$emit('update:modelValue', !modelValue)"
      :checked="modelValue"
    />
    <label for="res-checkbox">
      <slot></slot>
    </label>
  </div>
</template>

<script>
export default {
  name: 'res-checkbox',
  props: {
    modelValue: [Boolean]
  }
}
</script>

<style lang="scss" scoped>
.checkbox {
  position: absolute;
  z-index: -1;

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    font-size: 15px;

    label {
      display: flex;
      align-items: center;
      position: relative;

      &::before {
        content: '';
        display: inline-flex;
        width: 14px;
        height: 14px;
        cursor: pointer;
        border-radius: 3px;
        border: 1px solid var(--input-border);
        margin-right: 5px;
      }
    }
  }

  &:checked + label::before {
    background: var(--green) url('../../assets/check-mark.svg') no-repeat center center;
  }
}
</style>
