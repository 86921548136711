export default {
  loginSuccess: 'Вы успешно авторизовались',
  logout: 'Вы успешно вышли',
  changePasswordSuccess: 'Пароль успешно изменен',

  invoicesUpdateSuccess: 'Счета успешно изменены',

  changeInvoiceSuccess: 'Счет успешно изменен',
  changeInvoiceAgreedSuccess: 'Статус согласования успешно изменен',
  removeInvoiceSuccess: 'Счет был успешно удален',
  invoicesAddSuccess: 'Новый счет был успешно добавлен',

  invoiceSpecificationAddSuccess: 'Новая спецификация успешно добавлена',
  invoiceSpecificationChangeSuccess: 'Cпецификация успешно изменена',
  invoiceSpecificationRemoveSuccess: 'Cпецификация успешно удалена',

  invoiceSpecificationBindSuccess: 'Cпецификация успешно привязана',
  invoiceSpecificationUnBindSuccess: 'Cпецификация успешно отвязана',

  registerSuccess: 'Регистрация прошла успешно',

  carEventAddSuccess: 'Событие успешно добавлено',
  carEventRemoveSuccess: 'Событие успешно удалено',
  carEventUpdateSuccess: 'Событие успешно обновленно',

  carComplaintRemoveSuccess: 'Замечание успешно удалено',
  carComplaintAddSuccess: 'Замечание успешно добавлено',

  carInfoUpdateSuccess: 'Информация успешно обновленна',
  carEventInfoAddSuccess: 'Путевой лист обновлён',
  carMessageSuccess: 'Сообщение успешно отправлено',

  createMaintenanceUnitSuccess: 'Создание объекта ПТО прошло успешно',
  editMaintenanceUnitSuccess: 'Редактирование объекта ПТО прошло успешно',
  deleteMaintenanceUnitSuccess: 'Удаление объекта ПТО прошло успешно',

  createMaintenanceContactSuccess: 'Создание контакта прошло успешно',
  deleteMaintenanceContactSuccess: 'Удаление контакта прошло успешно',
  closeMaintenanceTaskSuccess: 'Закрытие задачи ПТО прошло успешно',

  createRemouteObjSuccess: 'Создание объекта прошло успешно',
  editRemouteObjSuccess: 'Редактирование объекта прошло успешно',
  deleteRemouteObjSuccess: 'Удаление объекта прошло успешно',

  createRemouteProblemsSuccess: 'Создание события прошло успешно',
  editRemouteProblemsSuccess: 'Редактирование события прошло успешно',
  deleteRemouteProblemsSuccess: 'Удаление события прошло успешно',

  createRemouteInfoSuccess: 'Добавление информации прошло успешно',
  editRemouteInfoSuccess: 'Редактирование информации прошло успешно',
  deleteRemouteInfoSuccess: 'Удаление информации прошло успешно',

  createRemouteDostupSuccess: 'Добавление информации прошло успешно',
  editRemouteDostupSuccess: 'Редактирование информации прошло успешно',
  deleteRemouteDostupSuccess: 'Удаление информации прошло успешно',

  createRemouteContacterSuccess: 'Создание контакта прошло успешно',
  deleteRemouteContacterSuccess: 'Удаление контакта прошло успешно',

  createMonitoringNotificationSuccess: 'Создание оповещения прошло успешно',
  updateMonitoringNotificationSuccess: 'Редактирование оповещения прошло успешно',
  deleteMonitoringNotificationSuccess: 'Удаление оповещения прошло успешно',

  createMonitoringNotificationRecipientSuccess: 'Создание получателя прошло успешно',
  updateMonitoringNotificationRecipientSuccess: 'Редактирование получателя прошло успешно',
  deleteMonitoringNotificationRecipientSuccess: 'Удаление получателя оповещения прошло успешно',

  contractCreateSuccess: 'Добавление нового договора прошло успешно',
  contractEditSuccess: 'Редактирование договора прошло успешно',

  contractStepCreateSuccess: 'Добавление нового этапа договора прошло успешно',
  contractStepEditSuccess: 'Редактирование этапа договора прошло успешно',
  contractStepDeleteSuccess: 'Удаление этапа договора прошло успешно',

  contractAgreementCreateSuccess: 'Добавление доп. соглашения договора прошло успешно',
  contractAgreementDeleteSuccess: 'Удаление доп. соглашения договора прошло успешно',

  createCustomerSuccess: 'Создание заказчика прошло успешно',
  editCustomerSuccess: 'Редактирование заказчика прошло успешно',
  deleteCustomerSuccess: 'Удаление заказчика прошло успешно',

  createTagSuccess: 'Создание тега прошло успешно',
  editTagSuccess: 'Редактирование тега прошло успешно',
  deleteTagSuccess: 'Удаление тега прошло успешно',

  createReszipElectrometerSuccess: 'Добавление счётчика прошло успешно',
  editReszipElectrometerSuccess: 'Редактирование счётчика прошло успешно',
  deleteReszipElectrometerSuccess: 'Удаление счётчика прошло успешно',

  createReszipHistorySuccess: 'Создание записи в истории прошло успешно',
  editReszipHistorySuccess: 'Редактирование записи в истории прошло успешно',
  deleteReszipHistorySuccess: 'Удаление записи в истории прошло успешно',

  createReszipAnyequipmentSuccess: 'Создание типа оборудования прошло успешно',
  editReszipAnyequipmentSuccess: 'Изменение типа оборудования прошло успешно',
  deleteReszipAnyequipmentSuccess: 'Удаление типа оборудования прошло успешно',

  createReszipEquipmentSuccess: 'Создание оборудования прошло успешно',
  editReszipEquipmentSuccess: 'Редактирование оборудование прошло успешно',
  deleteReszipEquipmentSuccess: 'Удаление оборудования прошло успешно',

  createWorkloadSuccess: 'Создание объекта задач прошло успешно',
  editWorkloadSuccess: 'Редактирование объекта задач прошло успешно',
  deleteWorkloadSuccess: 'Удаление объекта задач прошло успешно',

  createWorkloadTaskSuccess: 'Создание задачи прошло успешно',
  editWorkloadTaskSuccess: 'Редактирование задачи прошло успешно',
  deleteWorkloadTaskSuccess: 'Удаление задачи прошло успешно',

  deleteWorkloadTaskFileSuccess: 'Удаление файла задачи прошло успешно',

  changeWorkloadTaskConfirmationSuccess: 'Статус утверждения успешно изменен',
  changeWorkloadTaskAgreedSuccess: 'Статус согласования успешно изменен',

  createWorkloadPtoSuccess: 'Объект ПТО успешно создан',
  editWorkloadPtoSuccess: 'Объект ПТО обновлен',
  deleteWorkloadPtoSuccess: 'Объект ПТО успешно удален',

  createWorkloadTaskPto: 'Запланирован выезд ПТО',
  changeWorkloadStatusTaskPto: 'Задача ПТО обновлена',
  deleteWorkloadStatusTaskPto: 'Задача ПТО удалена'
}
