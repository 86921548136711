import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: { title: 'Мониторинг | Главная', layout: 'master', protected: true }
  },
  {
    path: '/monitoring',
    name: 'monitoring',
    component: () => import('@/views/Monitoring.vue'),
    meta: { title: 'Мониторинг | Объекты', layout: 'master', protected: true, permissions: 'observer' }
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('@/views/Maintenance.vue'),
    meta: { title: 'Мониторинг | ПТО', layout: 'master', protected: true, permissions: 'observer' }
  },
  {
    path: '/cars',
    name: 'cars',
    component: () => import('@/views/Cars.vue'),
    meta: { title: 'Мониторинг | Автомобили', layout: 'master', protected: true, permissions: 'motorist' }
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: () => import('@/views/Invoices.vue'),
    meta: { title: 'Мониторинг | Счета', layout: 'master', protected: true, permissions: 'accounter' }
  },
  {
    path: '/contracts',
    name: 'contracts',
    component: () => import('@/views/Contracts.vue'),
    meta: { title: 'Мониторинг | Договора', layout: 'master', protected: true, permissions: 'contractor' }
  },
  {
    path: '/contracts/:contractId(\\d+)',
    name: 'contractDetail',
    component: () => import('@/views/ContractDetail.vue'),
    meta: { title: 'Мониторинг | Договор', layout: 'master', protected: true, permissions: 'contractor' }
  },
  {
    path: '/contracts/customers',
    name: 'contractCustomers',
    component: () => import('@/views/СontractCustomers.vue'),
    meta: { title: 'Мониторинг | Заказчики', layout: 'master', protected: true, permissions: 'contractor' }
  },
  {
    path: '/staff-workload',
    name: 'staffWorkload',
    component: () => import('@/views/StaffWorkLoad.vue'),
    meta: { title: 'Мониторинг | Загрузка персонала', layout: 'master', protected: true, permissions: 'workload' }
  },
  {
    path: '/lectures',
    name: 'lectures',
    component: () => import('@/views/Lectures.vue'),
    meta: { title: 'Мониторинг | Лекции', layout: 'master', protected: true, permissions: 'lectures' }
  },
  {
    path: '/remoute-access',
    name: 'remouteAccess',
    component: () => import('@/views/RemouteAccess.vue'),
    meta: { title: 'Мониторинг | Удаленный доступ', layout: 'master', protected: true, permissions: 'remouteaccess' }
  },
  {
    path: '/reszip',
    name: 'reszip',
    component: () => import('@/views/ResZip.vue'),
    meta: { title: 'Мониторинг | ЗИП', layout: 'master', protected: true, permissions: 'reszip' }
  },
  {
    path: '/ats',
    name: 'ats',
    component: () => import('@/views/AtsExamination.vue'),
    meta: { title: 'Мониторинг | Испытания', layout: 'master', protected: true, permissions: 'ats' }
  },
  {
    path: '/testpage',
    name: 'testpage',
    component: () => import('@/views/TestPage.vue'),
    meta: { title: 'Мониторинг | Тестовая страница', layout: 'master', protected: true, permissions: 'observer' }
  },
  {
    path: '/utilities',
    name: 'utilities',
    component: () => import('@/views/Utilities.vue'),
    meta: { title: 'Мониторинг | Утилиты', layout: 'master', protected: true }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: { title: 'Мониторинг | Авторизация', layout: 'empty' }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import('@/views/NotFound.vue'),
    meta: { title: 'Мониторинг | Страница не найдена', layout: 'empty' }
  }
]

const getFirstAllowedUserAppPath = (user) =>
  routes.filter((route) => route.meta.permissions).find((route) => user.groups.includes(route.meta.permissions))?.path

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const user = JSON.parse(localStorage.getItem('user'))
  if (to.name != 'login' && !user) {
    next('/login')
  } else if (
    (to.meta.permissions && user.groups.includes(to.meta.permissions)) ||
    to.name.match('notFound|login|utilities')
  ) {
    document.title = to.meta.title
    next()
  } else {
    const allowedPath = getFirstAllowedUserAppPath(user)
    allowedPath ? next(allowedPath) : next()
  }
})

export default router
