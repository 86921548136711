<template>
  <transition name="fade">
    <div v-if="show" @click.stop="closeModal" class="modal">
      <div @click.stop class="modal__wrapper">
        <div class="modal__header">
          <div class="modal__group">
            <span v-if="status !== null" :class="[status ? 'green' : 'red']" class="modal__status" />
            <div class="modal__header-text">
              <div class="modal__title"><slot name="title" /></div>
              <div class="modal__subtitle"><slot name="subtitle" /></div>
            </div>
          </div>
          <div class="modal__group">
            <div @click="closeModal" class="modal__close">
              <font-awesome-icon icon="times" />
            </div>
          </div>
        </div>
        <div class="modal__body"><slot name="body">Тело модального окна</slot></div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'res-modal',
  props: {
    show: { type: Boolean, required: true },
    status: { type: Boolean, required: false, default: null }
  },
  methods: {
    closeModal() {
      this.$emit('update', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease;
  animation: fade 0.5s;
  padding: 30px;
  z-index: 100;

  &__wrapper {
    background: var(--background-header);
    padding: 10px;
    border-radius: 4px;
    min-width: 500px;
    min-height: 250px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  &__body {
    height: 100%;
    width: 100%;
  }

  &__group {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 7px;
  }

  &__title {
    font-size: 17px;
  }

  &__subtitle {
    font-size: 14px;
    margin-top: 2px;
  }

  &__close {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;

    cursor: pointer;
    transition: all 0.3s ease;
    width: 28px;
    height: 28px;
    border-radius: 4px;
    font-size: 28px;

    &:hover {
      filter: brightness(1.1);
    }

    &:active {
      filter: brightness(1);
    }
  }
}
.fade {
  &-enter-active,
  &-leave-active {
    transition: opacity 0.2s ease;
  }
  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
}
</style>
